
import { Vue, Component, Watch } from 'vue-property-decorator';

import { InteractiveHelpSection, SelectorSourceContainer, HelpStepDataLocation, HelpStepDataAlignment, InteractiveHelpStep } from '@/models/InteractiveHelp';

import ConfigurationService from '@/services/ConfigurationService';
import { Route } from 'vue-router';
import { setTimeout } from 'timers';

@Component
export default class InteractiveHelp extends Vue {
  private readonly configService: ConfigurationService;

  private readonly helpSections: InteractiveHelpSection[];
  private step: number = 0;
  private init: boolean = false;
  private stepClasses: string[] = [];
  private section: InteractiveHelpSection | null;
  private lastHelperClick: number = 0;

  private opened: boolean = true;

  constructor () {
    super();

    this.configService = ConfigurationService.getInstance();

    this.helpSections = [
      {
        id: 'activity-tracking',
        steps: [
          {
            title: 'Activity Tracking',
            description: `Welcome to our new Activity Tracking view (BETA). With this tool <b>you should expect a dramatic effort reduction on your monthly activity tracking</b> - and <b class="text-success">we expect you to notify us when it it not, to help us fix that.</b></br>
            This getting-started tour will show you through the tool and its features, so you are quickly up and running. Shall we get started?
            `,
            selector: 'body',
            selectorSource: SelectorSourceContainer.global,
            dataAlignment: HelpStepDataAlignment.center,
            style: {},
            color: '#067f68'
          },
          {
            title: 'Calendar view',
            description: `<div class="well bg-light" style="padding: 1em">This view shows you the whole month and allows you to modify and validate your activity reports. <b class="text-warning">Past months are read-only.</b></div> `,
            selector: '.calendar-container',
            selectorSource: SelectorSourceContainer.content,
            dataAlignment: HelpStepDataAlignment.left,
            dataLocation: HelpStepDataLocation.center,
            style: {},
            color: 'green'
          },
          {
            title: 'Generate events',
            description: `If you don't see any of your days with an activity to report, you can click this button. It will generate default reports for any empty day.`,
            selector: 'button.generate-reports',
            selectorSource: SelectorSourceContainer.content,
            dataAlignment: HelpStepDataAlignment.left,
            dataLocation: HelpStepDataLocation.left,
            style: {},
            color: 'orange',
            autoContinue: {
              message: 'Click the generate button to continue',
              handler: (step: InteractiveHelpStep) => {
                const targetItem = document.querySelector(step.selector)! as any;
                const clickEvent = new MouseEvent('click', {
                  view: window,
                  bubbles: true,
                  cancelable: false
                });

                targetItem.dispatchEvent(clickEvent);

                setTimeout(() => {
                  this.nextStep();
                }, 1000)
              }
            }
          },
          {
            title: 'Days',
            description: `On any given day, you should have an event like this one. Events represent where you spend your daily efforts - e.g. customer engagements - and <i>special</i> activities - e.g. holidays. By default, events are generated with the 'unassigned' activity.`,
            selector: '.day',
            selectorSource: SelectorSourceContainer.content,
            dataAlignment: HelpStepDataAlignment.left,
            dataLocation: HelpStepDataLocation.right,
            style: {},
            color: 'blue',
            autoContinue: {
              message: 'Click the event to edit it.',
              handler: (step: InteractiveHelpStep) => {
                const targetItem = document.querySelector(step.selector)! as any;
                const eventItem = targetItem.querySelector('.calendar-event .title') as any;
                const clickEvent = new MouseEvent('click', {
                  view: window,
                  bubbles: true,
                  cancelable: false
                });

                eventItem.dispatchEvent(clickEvent);

                setTimeout(() => {
                  this.nextStep();
                }, 1000);
              }
            }
          },
          {
            title: 'Event edition',
            description: `When you click on an event, this window should appear. In it, you can select all attributes of the activity:
            <ul>
              <li><b>Activity:</b> You can select an active customer engagement, or any special activity. Engagements of your assigned competency should be easily accessible.</li>
              <li><b>Location:</b> Where you'd develop this activity - i.e. <i>on-site</i> or <i>remote</i>. While you can select this field for any activity selection, it's only considered for customer engagements.</li>
              <li><b>Process:</b> When on customer engagements, select what process that event represent - e.g. preparation, execution, handover.</li>
            </ul>`,
            selector: '.update-event.open',
            selectorSource: SelectorSourceContainer.content,
            dataAlignment: HelpStepDataAlignment.right,
            dataLocation: HelpStepDataLocation.left,
            style: {},
            color: '#dbd525',
            autoContinue: {
              message: 'Click on the edit window and select an engagement, a location, and a process.',
              handler: (step: InteractiveHelpStep) => {
                const targetItem = document.querySelector(step.selector)! as any;
                const previousZIndex = targetItem.style.zIndex
                targetItem.style.zIndex = 100000000

                let activity: any, location: any, process: any;

                function verify () {
                  if (activity && location && process) return true
                  return false
                }

                // Watchers
                const items = targetItem.querySelectorAll('select');
                items.forEach((item: any, index: number) => {
                  item.addEventListener('change', (event: any) => {
                    const value = item.value;
                    switch (index) {
                      case 0:
                        activity = value;
                        break;
                      case 1:
                        location = value;
                        break;
                      case 2:
                        process = value;
                        break;
                    }

                    if (verify()) {
                      targetItem.style.zIndex = previousZIndex;
                      this.nextStep()
                    }
                  });
                });
              }
            }
          },
          {
            title: 'Event propagation',
            description: `Once you've selected those three fields you're ready to go. However, if you did the same thing during more days in a row, this feature will allow you to propagate your record until your given date. We will change the records for you for all the days within the defined period.`,
            selector: '.update-event.open input[type="date"]',
            inheritParentPosition: true,
            selectorSource: SelectorSourceContainer.content,
            dataAlignment: HelpStepDataAlignment.left,
            dataLocation: HelpStepDataLocation.left,
            style: {},
            color: 'green',
            autoContinue: {
              message: 'Select a day in the future. <b>Don\'t go too far!</b>',
              handler: (step: InteractiveHelpStep) => {
                const targetItem = document.querySelector(step.selector)! as any;
                const container = document.querySelector('.update-event.open')! as any;

                const previousZIndex = container.style.zIndex
                container.style.zIndex = 100000000

                targetItem.addEventListener('change', (event: any) => {
                  const value = targetItem.value;
                  container.style.zIndex = previousZIndex;
                  this.nextStep()
                });
              }
            }
          },
          {
            title: 'Save event',
            description: `Once you save we will populate the database with the new information.`,
            selector: '.update-event.open button#save',
            selectorSource: SelectorSourceContainer.content,
            dataAlignment: HelpStepDataAlignment.right,
            dataLocation: HelpStepDataLocation.left,
            style: {},
            color: 'orange',
            autoContinue: {
              message: 'Click save.',
              handler: (step: InteractiveHelpStep) => {
                const targetItem = document.querySelector(step.selector)! as any;
                const clickEvent = new MouseEvent('click', {
                  view: window,
                  bubbles: true,
                  cancelable: false
                });

                targetItem.dispatchEvent(clickEvent);
                this.nextStep();
              }
            }
          },
          {
            title: 'Direct edits',
            description: `When a daily activity is a customer engagement, you can change the location and process directly. Simply hover on a given day, and certain icons will appear`,
            selector: '.day .calendar-event',
            selectorSource: SelectorSourceContainer.content,
            dataAlignment: HelpStepDataAlignment.left,
            dataLocation: HelpStepDataLocation.right,
            style: {},
            color: 'orange',
            autoContinue: {
              message: 'Click on the event to see the icons.',
              handler: (step: InteractiveHelpStep) => {
                const targetItem = document.querySelector(step.selector)! as any;
                targetItem.className += ' manually-opened'
                this.nextStep()
              }
            }
          },
          {
            title: 'Direct edits',
            description: `On the top row you can change the process, from design-review to handover. The bottom row allows you to change the location - i.e. on-site or remote. The check icon at the right of the event allows you to validate the activity. <b>Validation means your record is final, and usable by the leadership team.</b> All events must be validated at the end of each month`,
            selector: '.day .calendar-event',
            selectorSource: SelectorSourceContainer.content,
            dataAlignment: HelpStepDataAlignment.left,
            dataLocation: HelpStepDataLocation.right,
            style: {},
            color: 'orange'
          },
          {
            title: 'Weekly helpers',
            description: `The same actions you have available individually for each event can easily be applied to the whole week. Simply use the icons within this section`,
            selector: '.weekly-helper .week',
            selectorSource: SelectorSourceContainer.content,
            dataAlignment: HelpStepDataAlignment.left,
            dataLocation: HelpStepDataLocation.right,
            style: {},
            color: 'green'
          },
          {
            title: 'Legend',
            description: `You can find all legend information on how the events appearances mean.`,
            selector: '.show-legend',
            selectorSource: SelectorSourceContainer.content,
            dataAlignment: HelpStepDataAlignment.right,
            dataLocation: HelpStepDataLocation.left,
            style: {},
            color: 'blue'
          },
          {
            title: 'You are set!',
            description: `That's it! You should be ready to use the activity tracker now.`,
            selector: 'body',
            selectorSource: SelectorSourceContainer.global,
            dataAlignment: HelpStepDataAlignment.center,
            dataLocation: HelpStepDataLocation.left,
            style: {},
            color: '#067f68'
          }
        ]
      }
    ];

    this.section = null;
  }

  mounted () {
    this.init = true;
  }

  get currentStep () {
    if (!this.section) return undefined;
    const step = this.section.steps[this.step];

    const targetItem = document.querySelector(step.selector);
    if (!targetItem) {
      return null;
    }

    return step;
  }

  get isAlreadySeen () {
    if (!this.section) return false;
    const config = this.configService.get(`TOUR-${this.section.id}-COMPLETED`, true);
    return !!config;
  }

  get isFirstStep () {
    if (!this.section) return false;
    return this.step === 0;
  }

  get isLastStep () {
    if (!this.section) return false;
    return this.step + 1 === this.section.steps.length;
  }

  get stepPosition () {
    if (!this.section) return false;
    const step = this.currentStep;
    if (!step) return null;

    const style: { [key: string]: any } = {};
    const targetItem = document.querySelector(step.selector)!;

    const stepSource = step.selectorSource;

    const elementBounds = targetItem.getBoundingClientRect();
    const { top, left, width, height } = elementBounds;

    style.top = `${top}px`;
    style.left = `${left}px`;
    style.width = `${width}px`;
    style.height = `${height}px`;

    return style;
  }

  get stepDataStyle () {
    const step = this.currentStep;
    if (!step) return null;

    const style: { [key: string]: any } = {};
    style.color = step.color;

    return style;
  }

  get stepStyle () {
    const step = this.currentStep;
    if (!step) return null;

    const styleDef = step.style;
    const style: { [key: string]: any } = { ...styleDef };
    style['outline-color'] = step.color;

    return style;
  }

  closeHelp () {
    this.opened = false;
    this.section = null;
  }

  finishHelp () {
    const tourCompletedFlagName = `TOUR-${this.section!.id}-COMPLETED`;
    this.configService.set(tourCompletedFlagName, true);
    this.configService.persist(tourCompletedFlagName);
    this.closeHelp();
  }

  nextStep () {
    this.stepClasses = ['move'];
    setTimeout(() => { this.stepClasses = [] }, 500);
    this.step++;
  }

  previousStep () {
    this.stepClasses = ['move'];
    setTimeout(() => { this.stepClasses = [] }, 500);
    this.step--;
  }

  stepHelperClick () {
    const clickTime = new Date().getTime();
    const diff = clickTime - this.lastHelperClick;
    if (diff < 2000) {
      return
    }
    this.lastHelperClick = clickTime
    const step = this.currentStep!;
    if (step.autoContinue && step.autoContinue.handler) {
      step.autoContinue.handler(step);
    } else {
      console.warn('WARN: No auto continue handler, other handlers not implemented.')
    }
  }

  @Watch('$route', { immediate: true })
  onRouteChanged (route: Route) {
    this.opened = true;
    const interactiveHelpConfig = route.meta?.interactiveHelp;
    const section = !interactiveHelpConfig ? null : this.helpSections.filter(section => section.id === interactiveHelpConfig)[0];
    this.section = section || null;
  }
}
