
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MenuItem extends Vue {
  @Prop(String) readonly icon!: string;
  @Prop(String) readonly label!: string;
  @Prop(String) readonly link!: string;

  async created () {}

  async mounted () {}
}
