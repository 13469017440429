import { Auth } from "aws-amplify";

export default class AuthService {
  private static instance?: AuthService;
  public user: string | undefined;
  public team: string | undefined;

  async init() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const idToken = await (await Auth.currentSession()).getIdToken();
      const payload = idToken.decodePayload();

      this.user =
        user.attributes.preferred_username;

      this.team = payload.teamId;
    } catch (_err) {
      await Auth.federatedSignIn();
    }
    return this;
  }

  static getInstance() {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }
}
