import moment from 'moment';
import { v4 as uuid } from 'uuid';
import AuthService from '../services/AuthService';
import { Phase } from './Engagement';

export enum InteractionAttendeeRole {
  FDE = "FDE",
  SDE = "SDE",
  SRE = "SRE",
  PROGRAM_MANAGER = "Program Manager",
  PRINCIPAL_ENGINEER = "Principal Engineer",
  SOLUTIONS_ARCHITECT = "Solutions Architect",
  ENTERPRISE_ARCHITECT = "Enterprise Architect",
  ENGINEERING_MANAGER = "Engineering Manager",
  DIRECTOR_OF_ENGINEERING = "Director of Engineering",
  DATA_ANALYST = "Data Analyst",
  VP = "VP",
  CTO = "CTO",
  CEO = "CEO",
}

export interface EngagementFeedback {
  Highlights?: EngagementInteractionItem[];
  Lowlights?: EngagementInteractionItem[];
  Issues?: EngagementInteractionItem[];
  Quotes?: EngagementInteractionItem[];
  Opportunities?: EngagementInteractionOpportunity[];
  FollowUps?: EngagementInteractionTaskProps[];
}

export enum InteractionType {
  Workshop = 'Workshop',
  CustomerCall = 'Customer Call',
  Internal = 'Internal',
  OtherUpdate = 'Other Update',
}

export interface EngagementInteraction extends EngagementFeedback {
  phase: Phase;
  title: string;
  type: InteractionType;
  date: string;
  location: string;
  details: string
  attendeeNo: number;
  attendeeRoles: InteractionAttendeeRole[];
  participants: string[];
}

export interface EngagementInteractionItem {
  Subjects?: string[];
  Text: string;
  // TODO
}

export interface EngagementInteractionOpportunity extends EngagementInteractionItem {
  Today?: string;
  Future?: string;
  Action?: string;
}

export interface EngagementInteractionTaskProps extends EngagementInteractionItem {
  TaskId: string;
  Owner?: string;
  Assignee?: string;
  CreatedAt: string;
  DueDate?: string;
  Saved?: boolean;
  Submitted?: boolean;
  Fulfilled?: boolean;
}

export class EngagementInteractionTask implements EngagementInteractionTaskProps {
  public readonly originalText?: string;
  private readonly props: EngagementInteractionTaskProps;

  constructor(props: EngagementInteractionTaskProps, originalText?: string) {
    this.props = props;
    this.originalText = originalText;
  }

  static fromExistingTask (props: EngagementInteractionTaskProps, text: string) {
    const expression = /^\s*\[(x?)\] \(([\dabcdef]{12})\) (.+)\. Assigned to: (.+)$/;
    const match: string[] = text.match(expression)!;
    const fulfilled = match[1] === 'x';
    const subject = match[3];
    const assignee = match[4];

    if (!props) {
      const date = moment().format('YYYY-MM-DD HH:mm:ss');

      props = {
        TaskId: match[2],
        CreatedAt: date,
        Text: subject,
        Assignee: assignee,
        Fulfilled: fulfilled
      }
    }

    const isChanged =
      fulfilled !== props.Fulfilled ||
      subject !== props.Text ||
      assignee !== props.Assignee;

    const owner = isChanged ? AuthService.getInstance().user! : props.Owner!;

    props.Fulfilled = fulfilled;
    props.Text = subject;
    props.Assignee = EngagementInteractionTask.parseAssignee(assignee, owner);

    return new EngagementInteractionTask(props, text);
  }

  static fromMarkdownExpression (text: string): EngagementInteractionTask {
    const todoExpression = /^\s*\[(x?)\] TODO (.+) ?(\((customer|account|devax|me)\))?(\s*\.*\?+!+\s*)?$/i;
    // const dueDateExpression = /(before|on|due|expires)\s(next|following|this)?\s?(week|month|monday|tuesday|wednesday|thursday|friday|mon|tue|wed|thu|fri|year|)/

    const match = text.replace(/^TODO/, '[] TODO').match(todoExpression);

    const Owner = AuthService.getInstance().user!;

    // TODO Control exceptions
    const fulfilled = match![1] === 'x'
    const subject = match![2];
    const possibleAssignee = (subject.match(/\((customer|account|devax|me)\)(\s*\.\?!\s*)?$/) || [])[1];
    const assignee = ((match || [])[3] || possibleAssignee || '').replace(/\(\)/g, '').toLowerCase();

    let Assignee = EngagementInteractionTask.parseAssignee(assignee, Owner);
    const Text = possibleAssignee ? subject.split(`(${possibleAssignee})`).join('').trim() : subject;

    const props: EngagementInteractionTaskProps = {
      CreatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      Owner,
      TaskId: uuid(),
      Text,
      Assignee,
      Saved: false,
      Submitted: false,
      Fulfilled: fulfilled,
    }

    return new EngagementInteractionTask(props, text);
  }

  static parseAssignee (assignee: string, owner: string) {
    switch (assignee) {
      case '':
      case 'me':
        return `${owner}@`;
      case 'account':
        return 'Account Team';
      case 'customer':
        return 'Customer Team';
      case 'service':
      case 'product':
        return 'Service Teams';
      default:
        return assignee;
    }
  }

  changeFulfillmentInText (text: string) {
    const expression = new RegExp(`- \\[x?\\] \\(${this.ShortId}\\)`, 'g');
    return text.replace(expression, `- [${this.props.Fulfilled ? 'x' : ''}] (${this.ShortId})`)
  }

  getProps () {
    return { ...this.props };
  }

  stringify() {
    return `[${this.props.Fulfilled ? 'x' : ''}] (${this.ShortId}) ${this.props.Text}. Assigned to: ${this.props.Assignee}`
  }

  get Fulfilled () {
    return this.props.Fulfilled!;
  }

  get TaskId () {
    return this.props.TaskId;
  }

  get ShortId () {
    return this.TaskId.split('-').pop();
  }

  get Owner () {
    return this.props.Owner;
  }

  get Assignee () {
    return this.props.Assignee;
  }

  get CreatedAt () {
    return this.props.CreatedAt;
  }

  get DueDate () {
    return this.props.DueDate;
  }

  get Text () {
    return this.props.Text;
  }

  get Subjects () {
    return this.props.Subjects;
  }
}
