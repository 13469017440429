import React, { useState, useCallback } from 'react';
import { Box, Button, Header, Input, Link, SpaceBetween, Table } from '@amzn/awsui-components-react';
import EngagementsService from '@/services/EngagementsService';
import { EngagementFileMeta } from '@devax/models/engagements/Engagement';
import FileModal from './FileModal';
import S3Service from '@/services/S3Service';

interface Props {
  engagementsService: EngagementsService;
  s3Service: S3Service;
}

export default function FileSearch(props: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [files, setFiles] = useState<EngagementFileMeta[]>([]);
  const [selectedFile, setSelectedFile] = useState<EngagementFileMeta|undefined>(undefined);
  const [searching, setSearching] = useState(false);

  const search = useCallback(async (searchTerm) => {
    if (!searchTerm) {
      setFiles([]);
    } else {
      setSearching(true);
      const result = await props.engagementsService.searchEngagementFiles(searchTerm);
      setFiles(result);
      setSearching(false);
    }
    setLastSearch(searchTerm);
  }, []);

  return (
    <>
      <Table<EngagementFileMeta>
        header={
          <Header
            variant='h2'
            description={`Showing results for "${lastSearch}"`}
            actions={
              <SpaceBetween direction='horizontal' size='m'>
                {/* @ts-ignore */}
                <Input
                  onKeyDown={e => {
                    if (e.detail.keyCode === 13) search(searchTerm);
                  }}
                  type='search'
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.detail.value)}
                />
                {/* @ts-ignore */}
                <Button
                  variant='primary'
                  onClick={() => search(searchTerm)}
                  loading={searching}
                >
                  Search
                </Button>
              </SpaceBetween>
            }
          >
            Files
          </Header>
        }
        items={files}
        columnDefinitions={[
          {
            id: "filename",
            header: "File name",
            cell: e => (
              // @ts-ignore
              <Link onFollow={() => setSelectedFile(e)}>
                {e.fileName}
              </Link>
            ),
            sortingField: "filename"
          },
          {
            id: "customer",
            header: "Customer",
            cell: e => (
              // @ts-ignore
              <Link href={`/engagements/${e.engagementId}`}>
                {e.customer}
              </Link>
            ),
            sortingField: "customer"
          },
          {
            id: "owner",
            header: "Owner",
            cell: e => e.owner,
            sortingField: "owner"
          },
          {
            id: "tags",
            header: "Tags",
            cell: e => e.tags.join(', '),
            sortingField: "tags"
          },
          {
            id: "description",
            header: "Description",
            cell: e => e.description,
            sortingField: "description"
          },
        ]}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No results</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              {lastSearch ? `No results for "${lastSearch}"` : 'Please enter a search term' }
            </Box>
          </Box>
        }
      />
      <FileModal
        fileMeta={selectedFile}
        onClose={() => setSelectedFile(undefined)}
        engagementsService={props.engagementsService}
        s3Service={props.s3Service}
      />
    </>
  )
}
