import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Input,
  FormField,
  Box,
  SpaceBetween,
  TokenGroup,
  Spinner
} from '@amzn/awsui-components-react';
import { EngagementFileMeta } from '@devax/models/engagements/Engagement';
import './FileModal.scss';
import EngagementsService from '@/services/EngagementsService';
import S3Service from '@/services/S3Service';
import { renderFilePreview } from '@/components/engagementFiles/EngagementFiles';

interface Props {
  fileMeta?: EngagementFileMeta;
  onClose: () => void;
  engagementsService: EngagementsService;
  s3Service: S3Service;
}

export default function FileModal(props: Props) {
  const [fetching, setFetching] = useState(true);
  const [file, setFile] = useState<any>(undefined);

  const fetchFile = async () => {
    setFetching(true);
    if (!props.fileMeta) return null;
    const file = await props.engagementsService.getEngagementFile(props.fileMeta?.fileKey);
    setFile(file);
    setFetching(false);
  }

  useEffect(() => {
    if (!props.fileMeta) return;
    fetchFile();
  }, [props.fileMeta]);

  const handleClose = () => {
    props.onClose();
    setFetching(true);
  }

  const getFile = async (file: any) => {
    const url = await props.s3Service.getSignedUrl(file.key);
    window.open(url, "_blank", "noopener,noreferrer");
  }

  if (!props.fileMeta) return null;

  return (
    <div id='file-modal'>
      <Modal
        size='large'
        onDismiss={handleClose}
        visible={!!props.fileMeta}
        header="View file"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              {/* @ts-ignore */}
              <Button
                variant="link"
                onClick={handleClose}
              >
                Cancel
              </Button>
              {/* @ts-ignore */}
              <Button
                variant="primary"
                onClick={async () => {
                  getFile(file);
                }}
                disabled={fetching}
              >
                Download
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <div id='file-modal-contents'>
          {fetching
            ? <Spinner />
            : (
              <div className='flex-container'>
                <div className='left'>
                  <SpaceBetween direction='vertical' size='m'>
                    <FormField
                      label="Customer"
                    >
                      {/* @ts-ignore */}
                      <Input
                        disabled
                        value={props.fileMeta.customer}
                        onChange={_e => {}}
                      />
                    </FormField>
                    <FormField
                      label="Filename"
                    >
                      {/* @ts-ignore */}
                      <Input
                        disabled
                        // value={getFilenameFromKey(props.fileMeta.fileKey) || ""}
                        value={props.fileMeta.fileName}
                        onChange={_e => {}}
                      />
                    </FormField>
                    <FormField
                      label="Owner"
                    >
                      {/* @ts-ignore */}
                      <Input
                        disabled
                        value={props.fileMeta.owner}
                        onChange={_e => {}}
                      />
                    </FormField>
                    <FormField
                      label="Description"
                    >
                      {/* @ts-ignore */}
                      <Input
                        disabled
                        value={props.fileMeta.description}
                        onChange={_e => {}}
                      />
                    </FormField>
                    <FormField
                      label="Tags"
                    >
                      <TokenGroup
                        onDismiss={_e => {}}
                        items={props.fileMeta.tags.map(tag => ({
                          label: tag
                        }))}
                      />
                    </FormField>
                  </SpaceBetween>
                </div>
                <div className='spacer' />
                <div className='right'>
                  <div className='preview'>
                    {renderFilePreview(file)}
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </Modal>
    </div>
  );
}
