
import moment from "moment";
import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerOnboardingRequest } from "@devax/models/customer-onboarding/CustomerOnboardingRequest";
import { TeamMember } from "../../models/Team";
import ConfigurationService from "../../services/ConfigurationService";
import AuthService from "../../services/AuthService";

@Component
export default class CustomerOnboardingTable extends Vue {
  @Prop(Array) readonly prospects!: CustomerOnboardingRequest[];
  @Prop(Array) readonly columns!: string[];
  @Prop(String) readonly title!: string;
  @Prop(Object) readonly teamFilter!: { value: string, options: string[]};
  @Prop(Boolean) readonly noFilter!: boolean;
  @Prop({ default: false }) readonly loading!: boolean;

  private readonly config: ConfigurationService =
    ConfigurationService.getInstance();

  public userList: TeamMember[] = [];
  public additionalCompetencies: any[] = [];
  public prospectNameFilter: string = "";
  public newCompetency: string = "";
  public usersTeam = AuthService.getInstance().team;

  get tableColumns() {
    return (
      this.columns || [
        "requestDate",
        "requestedBy",
        "team",
        "customer",
        "segment",
        "startDate",
      ]
    );
  }

  get tableTitle() {
    return this.title || "Customer onboarding requests";
  }

  get teamOptions() {
    const options: any = this.teamFilter.options.map((team: any) => ({ id: team.id, label: team.name || team.id }))
    options.unshift({ id: "any", label: "Any Team" });
    return options;
  }

  get filteredProspectList() {
    const filter = new RegExp(this.prospectNameFilter, "ig");
    const sortedList = this.sortedProspectList.filter((item) =>
      item.CustomerName.match(filter)
    );
    return sortedList;
  }

  get sortedProspectList() {
    if (this.teamFilter.value === 'any') return this.prospects.sort((a, b) => (a.CreatedAt < b.CreatedAt ? 1 : -1));
    return this.prospects;
  }

  get prospectTableColumnDefinition() {
    const allColumns = [
      {
        id: "requestDate",
        header: "Requested",
        cell: (item: CustomerOnboardingRequest) =>
          moment(item.CreatedAt).format("MMM DD, YYYY"),
      },
      {
        id: "requestedBy",
        header: "RequestedBy",
        cell: (item: CustomerOnboardingRequest) => `
          <div class="user-details">
            ${this.getUserColumn(item.UserId)}
          </div>
        `,
      },
      {
        id: "status",
        header: "Status",
        cell: (item: CustomerOnboardingRequest) => "Requested",
      },
      {
        id: "customer",
        header: "Customer",
        cell: (item: CustomerOnboardingRequest) =>
          `<a href="/customer-onboarding/${item.Id}">${item.CustomerName}</a>`,
      },
      {
        id: "team",
        header: "Team",
        cell: (item: CustomerOnboardingRequest) => item.TeamId,
      },
      {
        id: "segment",
        header: "Segment",
        cell: (item: CustomerOnboardingRequest) => item.BusinessSegment,
      },
      {
        id: "stage",
        header: "Stage",
        cell: (item: CustomerOnboardingRequest) => item.CustomerStage,
      },
      {
        id: "startDate",
        header: "Start date",
        cell: (item: CustomerOnboardingRequest) => item.EstimatedStartDate,
      },
    ];

    return allColumns
      .filter((c) => this.tableColumns.indexOf(c.id) !== -1)
      .sort(
        (a, b) =>
          this.tableColumns.indexOf(a.id) - this.tableColumns.indexOf(b.id)
      );
  }

  getUserColumn (userId: string) {
    return `
      <div class="user-details">
        <div class="avatar">
          <img src="${this.getUserImage(userId)}" />
        </div>
        <span class="name">${userId}</span>
      </div>
    `
  }

  getUserData(userId: string) {
    const user = this.userList.filter(
      (user) => user.preferred_username === userId
    )[0];
    if (!user) {
      return {
        given_name: "Unknown User",
        family_name: `(${userId})`,
      };
    }
    return user;
  }

  getUserImage(userId: string) {
    return `${this.config.get("EMPLOYEE_PICTURE_URL")}?uid=${userId}`;
  }
}
