import { Storage } from "aws-amplify";

import AuthService from "@/services/AuthService";
import ConfigurationService from "@/services/ConfigurationService";
import { Metadata } from "aws-sdk/clients/s3";

export default class S3Service {
  private static instance: S3Service;

  private readonly auth: AuthService;
  private readonly config: ConfigurationService;

  constructor() {
    this.auth = AuthService.getInstance();
    this.config = ConfigurationService.getInstance();
  }

  async deleteObject(key: string): Promise<any> {
    const result = await Storage.remove(key);
    return result;
  }

  async getSignedUrl(key: string): Promise<string> {
    const result = (await Storage.get(key, { expires: 60 })) as string;
    return result;
  }

  async getObject(key: string): Promise<any> {
    const result = await Storage.get(key, { download: true });
    return result;
  }

  async listObjects(prefix: string): Promise<any> {
    const result = await Storage.list(prefix);
    return result;
  }

  async putObject(key: string, body: any, metadata: Metadata = {}): Promise<any> {
    const result = await Storage.put(key, body, { metadata });
    return result;
  }

  static getInstance() {
    if (!S3Service.instance) S3Service.instance = new S3Service();
    return S3Service.instance;
  }
}
