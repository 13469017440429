
import { Vue, Component, Watch } from "vue-property-decorator";
import Framework from "./components/Framework.vue";
import InteractiveHelp from "./components/InteractiveHelp.vue";

import AuthService from "./services/AuthService";
import ConfigurationService from "./services/ConfigurationService";

@Component({
  components: {
  Framework,
  InteractiveHelp,
  }
  })
export default class App extends Vue {
  private readonly authService: AuthService = AuthService.getInstance();
  private links: Array<object> = [];

  get environment() {
    return ConfigurationService.getInstance().get("ENVIRONMENT");
  }

  get internalUser() {
    return !!this.authService.team;
  }

  get user() {
    return this.authService.user;
  }

  async created() {
  }

  @Watch("$route", { immediate: true })
  onRouteChanged() {
    this.links = [
      {
        text: "Home",
        href: "/"
      }
    ];
    if (this.$route.meta?.breadcrumbs !== undefined) {
      this.$route.meta.breadcrumbs.forEach((element: any) => {
        const text =
          typeof element.label === "function"
            ? element.label(this.$route)
            : element.label;
        const href =
          typeof element.href === "function"
            ? element.href(this.$route)
            : element.href;

        this.links.push({ text, href });
      });

      if (typeof this.$route.meta.breadcrumbTitle === "function") {
        this.links.push({
          text: this.$route.meta.breadcrumbTitle(this.$route),
          href: ""
        });
      }
    }
  }
}
