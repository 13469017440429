import { EngagementFeedback } from './EngagementInteraction';
import { Content } from './Content';

export enum EngagementReportStatus {
  CRAFTING  = 'craft',
  PUBLISHED = 'published',
  ARCHIVED  = 'archived'
}

export enum EngagementReportType {
  PROGRESS  = 'progress',
  CLOSURE   = 'closure'
}

export enum EngagementReportAudience {
  DEVAX       = 'DevAx',
  ACCOUNT     = 'Account Team',
  CUSTOMER    = 'Customer',
  LEADERSHIP  = 'Leadership'
}

export interface EngagementReport {
  ReportId: string;
  ReportType: EngagementReportType;
  EngagementId: string;
  Owner: string;
  Status: EngagementReportStatus;
  Title: string;
  Audience: EngagementReportAudience[];
  Summary: string;
  ReportDate: string;
  IncludedActionIds: string[];
  RawText?: string;
  Composition?: EngagementReportComposition;
}

export interface EngagementReportComposition {
  Summary: Content<any>[];
  Quotes: Content<any>[];
  Highlights: Content<any>[];
  Lowlights: Content<any>[];
  FollowUps: Content<any>[];
  Opportunities: Content<any>[];
}
