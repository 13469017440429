import Engagement from "@/models/Engagement";
import React, { useRef } from "react";
import { FileDrop as ReactFileDrop } from "react-file-drop";
import "./FileDrop.scss";

interface Props {
  engagement: Engagement;
  engagementFiles: any[];
  setEngagementFiles: (files: any[]) => void;
  onDropFiles: (files: FileList) => void;
  fileTypesAllowed: string[];
}

export default function FileDrop(props: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const filePicker = () => {
    inputRef.current && inputRef.current.click();
  };

  return (
    <div className="container">
      {/* @ts-ignore */}
      <ReactFileDrop onTargetClick={filePicker} onDrop={(f) => f && props.onDropFiles(f)}>
        <p className="placeholder">Drop your file</p>
        <input
          accept={props.fileTypesAllowed.map((type) => "." + type).join(", ")}
          value=""
          style={{ visibility: "hidden", opacity: 0 }}
          ref={inputRef}
          multiple
          type="file"
          onChange={(e) => e.target.files && props.onDropFiles(e.target.files)}
        />
      </ReactFileDrop>
    </div>
  );
}
