
import { Vue, Component } from 'vue-property-decorator'
import EngagementsService from '../../services/EngagementsService';
import AuthService from '../../services/AuthService';
import { CustomerOnboardingRequest } from '@devax/models/customer-onboarding';
import ProspectsService from '../../services/ProspectsService';
import Engagement, { Phase } from "../../models/Engagement";
import enumToOptions from '../../helpers/enumToOptions';

@Component({
  components: {
  }
  })
export default class EngagementContextOverview extends Vue {
  private readonly engagementsService: EngagementsService = EngagementsService.getInstance();
  private readonly authService: AuthService = AuthService.getInstance();
  private readonly propectsService: ProspectsService = ProspectsService.getInstance();

  public engagements: Engagement[] = [];
  public onboardingRequests: CustomerOnboardingRequest[] = [];

  get availablePhases() {
    return enumToOptions(Phase);
  }

  created () {
    this.engagementsService.listEngagementsByTeamId(this.authService.team!).then(engagements => { this.engagements = engagements.filter(e => !e.archived) });
    this.propectsService.listProspectByTeamId(this.authService.team!).then(prospects => { this.onboardingRequests = prospects });
  }
}
