
import moment from 'moment';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TeamMember } from '../models/Team';
import ConfigurationService from '../services/ConfigurationService';
import { EngagementReport } from '../models/EngagementReport';

@Component
export default class EngagementReportList extends Vue {
  private readonly configService: ConfigurationService = ConfigurationService.getInstance();

  @Prop(String) readonly engagementId!: string;
  @Prop(Array) readonly reports!: EngagementReport[];

  public userList: TeamMember[] = [];

  public engagementReportListColums = [
    {
      id: 'subject',
      header: 'Subject',
      cell: (item: EngagementReport) => `<a href="/engagements/${item.EngagementId}/reports/${item.ReportId}">${item.Title}</a>`
    },
    {
      id: 'date',
      header: 'Date',
      cell: (item: EngagementReport) => moment(item.ReportDate).format('MMM Do')
    },
    {
      id: 'author',
      header: 'Author',
      cell: (item: EngagementReport) => this.getUserColumn(item.Owner)
    }
  ]

  async mounted () { }

  getUserColumn (userId: string) {
    return `
      <div class="user-details">
        <div class="avatar">
          <img src="${this.getUserImage(userId)}" />
        </div>
        <span class="name">${this.getUserData(userId).given_name} ${this.getUserData(userId).family_name}</span>
      </div>
    `
  }

  getUserData (userId: string) {
    const user = this.userList.filter(user => user.preferred_username === userId)[0];
    if (!user) {
      return {
        given_name: 'Unknown User',
        family_name: `(${userId})`
      }
    }
    return user;
  }

  getUserImage (userId: string) {
    return `${this.configService.get('EMPLOYEE_PICTURE_URL')}?uid=${userId}`
  }
}
