import Amplify from "aws-amplify";
import AuthService from "@/services/AuthService";

const frontEndHost = window.location.protocol + "//" + window.location.host;
export const apiName = "apiEndpoint";

const setupAmplify = async (config: any) => {
  const amplifyConfig = {
    Auth: {
      mandatorySignIn: true,
      region: config.AWS_REGION,
      userPoolId: config.USER_POOL_ID,
      userPoolWebClientId: config.USER_POOL_CLIENT_ID,
      identityPoolId: config.IDENTITY_POOL_ID,
      oauth: {
        domain: config.COGNITO_CUSTOM_DOMAIN,
        scope: ["openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: frontEndHost,
        redirectSignOut: frontEndHost,
        responseType: "token",
        options: {
          AdvancedSecurityDataCollectionFlag: false
        }
      }
    },
    API: {
      endpoints: [
        {
          name: apiName,
          endpoint: config.API_ENDPOINT,
          region: config.AWS_REGION
        }
      ]
    },
    Storage: {
      AWSS3: {
        bucket: config.ENGAGEMENTS_DATA_BUCKET,
        region: config.AWS_REGION,
        serverSideEncryption: "aws:kms",
        SSEKMSKeyId: config.ENCRYPTION_KEY_ID,
        customPrefix: {
          public: ""
        }
      }
    }
  };

  await Amplify.configure(amplifyConfig);
  const auth = await AuthService.getInstance().init();
  if (!auth.user) throw new Error('User not logged in');
};

export default setupAmplify;
