
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FormDataInput, FormFieldType } from '@devax/models/base/forms/Form'
import DevaxFormFieldInput from './DevAxFormFieldInput.vue';

@Component({
  components: {
  DevaxFormFieldInput
  }
  })
export default class DevaxFormField extends Vue {
  @Prop() readonly value!: any;
  @Prop(Object) readonly field!: FormDataInput;
  @Prop(String) readonly fieldName!: string;
  @Prop(Boolean) readonly readonly!: boolean;

  get fieldValue () {
    return this.value;
  }

  set fieldValue (value: any) {
    this.$emit('input', value);
  }

  get fieldClassNames () {
    switch (this.fieldType) {
      case FormFieldType.IMAGE_URL:
        return 'text-and-image';
    }

    return [];
  }

  get labelWidth () {
    switch (this.fieldType) {
      case FormFieldType.COLOR:
        return '150px';
    }

    return undefined;
  }

  get fieldType () {
    const existingType = this.field.FieldType;
    if (existingType) return existingType;

    switch (this.field.Type) {
      case 'string':
      case undefined:
        return FormFieldType.TEXT;
      case 'number':
      case 'integer':
      case 'float':
      case 'double':
        return FormFieldType.NUMBER;
      case 'date':
        return FormFieldType.DATE;
      case 'boolean':
        return FormFieldType.SWITCH;
      default:
        return 'text'
    }
  }
}
