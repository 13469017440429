
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DevaxFormField from './DevAxFormField.vue';
import { FormData } from '@devax/models/base/forms/Form';

@Component({
  components: {
  DevaxFormField
  }
  })
export default class DevAxFormFields extends Vue {
  @Prop(Object) readonly value!: any;
  @Prop(Object) readonly definition!: FormData;
  @Prop(Boolean) readonly readonly!: boolean;

  get fieldValue () {
    return new Proxy(this.value, {
      get: (target: any, prop: string, receiver: any) => {
        return target[prop];
      },

      set: (target: any, prop: string, value: any) => {
        target[prop] = value;
        return true
      }
    });
  }
}
