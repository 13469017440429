import { v4 as uuid } from "uuid";
import AuthService from "@/services/AuthService";
import EngagementsService from "@/services/EngagementsService";
import { EngagementAction, EngagementActionProps } from "./EngagementActions";
import { AWSService } from "./AWSServices";
import { Vertical } from "@devax/models/engagements";

export enum Segment {
  ENT = "ENT",
  DNB = "DNB",
  SUB = "SUP",
  ENT_GF = "ENT-GF",
  DNB_GB = "DNB-GF",
  SUP_GF = "SUP-GF",
  SMB = "SMB",
  SMB_GF = "SMB-GF",
  ISV = "ISV",
  FCD = "FCD",
  DONT_KNOW = "Unknown",
}

export enum Stage {
  NO_MEANINGFUL_USAGE = "No meaningful usage",
  PROJECT = "Project",
  FOUNDATION = "Foundation",
  MIGRATION = "Migration",
  REINVENTION = "Reinvention",
  DONT_KNOW = "Unknown",
}

export enum Phase {
  PreQualification = "PreQualification",
  UnderQualification = "UnderQualification",
  Discovery = "Discovery",
  Build = "Build",
  Complete = "Complete",
  Disengaged = "Disengaged",
  Rejected = "Rejected",
}

export enum BusinessSize {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
  X_Large = "X-Large",
  XX_Large = "XX-Large",
  DONT_KNOW = "Unknown",
}

export enum RagStatus {
  Red = "Red",
  Amber = "Amber",
  Green = "Green",
  Blue = "Blue",
}

export default class Engagement {
  private readonly authService: AuthService = AuthService.getInstance();
  private readonly engagementsService: EngagementsService = EngagementsService.getInstance();
  public $editing: boolean = false;
  public readonly id: string = uuid();
  public userId: string = this.authService.user!;
  public teamId: string = this.authService.team!;
  public summary: string = "";
  public customerName: string = "";
  public phase?: Phase;
  public ragStatus?: RagStatus;
  public creationDate: Date = new Date();
  public formSubmissionDate?: Date;
  public customerCall?: boolean;
  public discoveryStartDate?: Date;
  public buildStartDate?: Date;
  public completionDate?: Date;
  public description: string = "";
  public businessSize?: BusinessSize;
  public segment?: Segment;
  public vertical?: Vertical;
  public stage?: Stage;
  public sfdc: string = "";
  public accountOwners: string[] = [];
  public accountSAs: string[] = [];
  public leadArchitects: string[] = [];
  public supportArchitects: string[] = [];
  public shadowArchitects: string[] = [];
  public interlocks: string[] = [];
  public reports: string[] = [];
  public awsServices: AWSService[] = [];
  public artefacts: string[] = [];
  public activeMembers: number = 0;
  public ownerTeam?: string;
  public archived: boolean = false;
  public onBoardingRequestId?: string;

  constructor(props?: any) {
    if (props) {
      for (const key in props) {
        if (Object.prototype.hasOwnProperty.call(props, key)) {
          (this as any)[key] = props[key];
        }
      }
    }
  }

  getProps() {
    const props = {};
    for (const key in this) {
      if (Object.prototype.hasOwnProperty.call(this, key)) {
        if (key === "$editing") continue;
        if (key === "$save") continue;
        if (key === "authService") continue;
        if (key === "engagementsService") continue;
        (props as any)[key] = this[key];
      }
    }
    return props;
  }

  async $create() {
    const result = await this.engagementsService.saveEngagement(this);
    if (result.statusCode === "400") throw new Error();
  }

  async $save(action: EngagementAction = EngagementAction.UpdateData, actionData?: { [key: string]: any }) {
    const result = await this.engagementsService.updateEngagement(this);
    if (result.statusCode === "400") throw new Error();

    const actionId = uuid();
    const actionDate = new Date().toISOString();

    const Item: EngagementActionProps = {
      engagementId: this.id,
      userId: this.authService.user!,
      teamId: this.authService.team!,
      action,
      id: actionId,
      actionData,
      actionDate,
    };

    await this.engagementsService.saveAction(this.id, Item);
  }
}
