
import { Vue, Component, Prop } from 'vue-property-decorator';
import { AWSServices, AWSService } from '@/models/AWSServices';

@Component({
  filters: {
  servicesToAutocomplete: (services: AWSService[]) => {
  return Object.values(services.map(service => ({
    id: `${service.section}##${service.service.trim()}`,
    section: service.section,
    label: service.service.trim()
    })).reduce((total, item) => ({
      ...total,
      [item.section]: {
      label: item.section,
      options: (((total as any)[item.section] || {}).options || []).concat([item])
      }
      }), {}));
  }
  }
  })
export default class AWSServicesInput extends Vue {
  @Prop(Array) readonly value!: AWSService[];

  private tag: string = '';

  get items () {
    return AWSServices;
  }

  get filteredItems () {
    return this.items.filter(item => item.service.match(new RegExp(this.tag, 'ig')));
  }

  get input () {
    return this.value ? this.value.map(item => `${item.section}##${item.service.trim()}`) : [];
  }

  set input (value: string[]) {
    this.$emit('input', value.map(item => {
      const data = item.split('##');
      return {
        section: data[0],
        service: data[1]
      }
    }));
  }
}
