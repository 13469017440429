
import TeamsService from "../services/TeamsService";
import AuthService from "../services/AuthService";

export default {
  name: "Settings",
  data() {
    return {
      inputValue: "",
      teamUrl: "",
      teamService: null,
      teamName: ""
    };
  },
  methods: {
    async submit() {
      const data = await (this as any).teamsService.updateTeam(
        AuthService.getInstance().team,
        {
          notificationUrl: (this as any).inputValue
        }
      );
      (this as any).teamUrl = data.notificationUrl;
    }
  },
  async created() {
    (this as any).teamName = AuthService.getInstance().team;
    (this as any).teamsService = TeamsService.getInstance();
    try {
      const data = await (this as any).teamsService.getTeamById(
        AuthService.getInstance().team
      );
      (this as any).teamUrl = data.notificationUrl;
    } catch (err) {}
  }
};
