
import {
  CustomerOnboardingForm,
  CustomerOnboardingRequest,
  OnboardingData,
} from "@devax/models/customer-onboarding";
import { Vue, Component } from "vue-property-decorator";
import ProspectsService from "../services/ProspectsService";
import EngagementService from "../services/EngagementsService";
import DevaxForm from "../components/forms/DevAxForm.vue";
import { FormFieldType } from "@devax/models/base/forms/Form";
import UserInfo from "../components/UserInfo.vue";
import AuthService from "../services/AuthService";
import FrameworkService from "../services/FrameworkService";

@Component({
  components: {
  DevaxForm,
  UserInfo,
  },
  filters: {
  tshirt(value: any) {
  const ret: any = {
  xs: "Extra Small",
  s: "Small",
  m: "Medium",
  l: "Large",
  xl: "Extra Large",
  };

  return ret[value];
  },
  },
  })
export default class CustomerOnboardingDetailsView extends Vue {
  private readonly fwkService: FrameworkService = FrameworkService.getInstance();
  public request: CustomerOnboardingRequest | null = null;
  public linkedEngagementId: string = '';

  private labels: any = {
    AccountOwners: "",
    SolutionsArchitects: "",
    TechnicalAccountManagers: "",
  };

  private fieldTypes: any = {
    AccountOwners: FormFieldType.USER_MULTIPLE,
    SolutionsArchitects: FormFieldType.USER_MULTIPLE,
    TechnicalAccountManagers: FormFieldType.USER_MULTIPLE,
  };

  copyId() {
    navigator.clipboard
      .writeText(this.request!.Id!)
      .then(() => this.fwkService.addAlert("success", "Successfully copied Id"))
      .catch(() => this.fwkService.addAlert("failure", "Failed to copy Id"));
  }

  get customerData() {
    const categories = this.onboardingData[0].categories.map((cat: any) => ({
      ...cat,
      form: {
        ...cat.form,
        Properties: cat.form.Properties
          ? Object.keys(cat.form.Properties)
            .map((k) => ({
              [k]: {
                ...cat.form.Properties[k],
                // Label: this.labels[k] !== undefined ? this.labels[k] : k,
                Description: undefined,
                FieldType: this.fieldTypes[k] || "label",
              },
            }))
            .reduce((t, i) => ({ ...t, ...i }))
          : {},
      },
    }));

    return {
      categories,
    };
  }

  get engagementData() {
    const categories = this.onboardingData[1].categories.map((cat: any) => ({
      ...cat,
      form: {
        ...cat.form,
        Properties: cat.form.Properties
          ? Object.keys(cat.form.Properties)
            .map((k) => ({
              [k]: {
                ...cat.form.Properties[k],
                // Label: this.labels[k] !== undefined ? this.labels[k] : k,
                Description: undefined,
                FieldType: this.fieldTypes[k] || "label",
              },
            }))
            .reduce((t, i) => ({ ...t, ...i }))
          : {},
      },
    }));

    return {
      categories,
    };
  }

  get onboardingData(): OnboardingData[] {
    return CustomerOnboardingForm;
  }

  get isTeam() {
    return !!AuthService.getInstance().team;
  }

  async created() {
    this.request = await ProspectsService.getInstance().getProspectById(
      this.$route.params.requestId
    );
    try {
      const engagement = await EngagementService.getInstance().getEngagementByOnBoardingRequest(this.$route.params.requestId);
      this.linkedEngagementId = engagement?.id;
    } catch (_err) {}
  }

  async mounted() {}
}
