
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
  }
  })
export default class PublicLandingBanner extends Vue {
  async created () {}

  async mounted () {}
}
