
import { Vue, Component } from "vue-property-decorator";
import Engagement from "../models/Engagement";
import EngagementDetailsForm from "../components/engagements/EngagementDetailsForm.vue";
import FrameworkService from "../services/FrameworkService";
import ProspectsService from "../services/ProspectsService";
import populateEngagementWithRequest from '../helpers/populateEngagementWithRequest';
import EngagementTeam from "../components/engagements/EngagementTeam.vue";
import EngagementAccountTeamSelection from "../components/EngagementAccountTeamSelection.vue";

@Component({
  components: {
  EngagementDetailsForm,
  EngagementTeam,
  EngagementAccountTeamSelection
  },
  })
export default class EngagementCreation extends Vue {
  private readonly fwkService: FrameworkService;

  public customerName: string = "Unknown";
  public engagementName: string = "Unknown";
  public formId: string | undefined;

  public relatedForm: any = null;

  public engagement: Engagement = new Engagement();

  public step: number = 0;

  constructor() {
    super();
    this.fwkService = FrameworkService.getInstance();
  }

  async created() {
    const hash = (window.location.hash || "#")
      .substring(1)
      .split("&")
      .map((item: string) => {
        const ret: { [key: string]: string } = {};
        const splitItem = item.split("=");
        ret[splitItem[0]] = splitItem[1];
        return ret;
      })
      .reduce((total: any, item: any) => ({ ...total, ...item }));

    if (hash.formId) {
      this.formId = hash.formId;
      this.relatedForm = await ProspectsService.getInstance().getProspectById(
        this.formId as string
      );
      populateEngagementWithRequest(this.engagement, this.relatedForm);
    }
  }

  async mounted() {}

  async saveEngagement() {
    try {
      await this.engagement!.$create();
      this.fwkService.addAlert("success", "Successfully created engagement");
      this.$router.push(`/engagements/${this.engagement!.id}`);
    } catch (error) {
      this.fwkService.addAlert("error", "Failed to create engagement");
    }
  }
}
