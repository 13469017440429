
import { Vue, Component, Prop } from 'vue-property-decorator'
import AuthService from '../services/AuthService';

@Component
export default class Banner extends Vue {
  @Prop({ type: String, default: 'AWS' }) readonly title?: string;

  get internalUser () {
    return !!AuthService.getInstance().team;
  }

  async created () {}

  async mounted () {}
}
