
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Engagement, {
  Phase,
  Segment,
  Stage,
  RagStatus,
  BusinessSize
} from "../../models/Engagement";
import enumToOptions from "../../helpers/enumToOptions";
import FrameworkService from "../../services/FrameworkService";

@Component
export default class EngagementDetailsForm extends Vue {
  @Prop(Engagement) readonly engagement!: Engagement;
  @Prop(Boolean) readonly titleEditable!: boolean;
  @Prop(Function) readonly submit!: Function;
  @Prop(Function) readonly clear!: Function;

  public engagementEnded: boolean = false;
  public completionDateErrorMsg: string = "";

  @Watch("engagement.phase", { immediate: true })
  setEngagementEndedStatus() {
    this.engagementEnded = [
      Phase.Complete,
      Phase.Disengaged,
      Phase.Rejected
    ].includes(this.engagement?.phase as never);
    if (!this.engagementEnded) this.engagement.completionDate = null as unknown as undefined;
  }

  @Watch("engagement.phase", { immediate: true })
  @Watch("engagement.completionDate", { immediate: true })
  setCompletionDateErrorMsg() {
    setTimeout(() => {
      if (this.engagementEnded && !this.engagement.completionDate) {
        this.completionDateErrorMsg = "please put a completion date";
      } else {
        this.completionDateErrorMsg = "";
      }
    }, 1000);
  }

  get availablePhases() {
    return enumToOptions(Phase);
  }

  get availableRagStatus() {
    return enumToOptions(RagStatus);
  }

  get availableBusinessSize() {
    return enumToOptions(BusinessSize);
  }

  get availableSegments() {
    return enumToOptions(Segment);
  }

  get availableStages() {
    return enumToOptions(Stage);
  }

  async created() {}

  async mounted() {
  }

  onSubmit() {
    if (this.engagementEnded && !this.engagement.completionDate) {
      FrameworkService.getInstance().addAlert(
        "error",
        "you missed a mandatory field"
      );
    } else {
      this.submit();
    }
  }
}
