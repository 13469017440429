
import { Vue, Component, Watch } from 'vue-property-decorator'
import CustomerOnboardingTable from '../components/onboarding/CustomerOnboardingTable.vue';
import { CustomerOnboardingRequest } from '@devax/models/customer-onboarding/CustomerOnboardingRequest';
import ProspectsService from '../services/ProspectsService';
import AuthService from "../services/AuthService";
import TeamsService from '../services/TeamsService';

@Component({
  components: {
  CustomerOnboardingTable
  }
  })
export default class CustomerOnboardingListView extends Vue {
  public prospects: CustomerOnboardingRequest[] = [];
  private loading: boolean = true;
  public chosenTeam = AuthService.getInstance().team;
  public teamFilter: any = { value: this.chosenTeam, options: [] };

  async created () {
    const teams = await TeamsService.getInstance().listTeams();
    this.teamFilter = { value: this.chosenTeam, options: teams };
  }

  @Watch('teamFilter.value', { immediate: true })
  async fetch () {
    this.loading = true;
    this.prospects = await ProspectsService.getInstance().listProspectByTeamId(this.teamFilter.value);
    this.loading = false;
  }
}
