
import { Vue, Component, Prop } from 'vue-property-decorator'
import Engagement from '../../models/Engagement'
import AuthService from '../../services/AuthService';
import UserInfo from '../UserInfo.vue';
import UserSelector from '../UserSelector.vue';

@Component({
  components: {
  UserInfo,
  UserSelector
  }
  })
export default class EngagementTeam extends Vue {
  @Prop(Engagement) readonly engagement!: Engagement;
  @Prop(Function) readonly submit!: Function;

  private readonly authService: AuthService = AuthService.getInstance();

  get user () {
    return this.authService.user!;
  }

  onSubmit() {
    this.submit();
  }

  async created () {}

  async mounted () {}
}
