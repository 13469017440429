
import { Vue, Component, Prop } from 'vue-property-decorator'
import Engagement from '@/models/Engagement';
import EngagementFollowUpList from '../../EngagementFollowUpList.vue';

@Component({
  components: {
  EngagementFollowUpList
  }
  })
export default class AssignmentFollowUps extends Vue {
  @Prop(Array) readonly engagements!: Engagement[];

  async created () {}

  async mounted () {}
}
