
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Engagement from '../models/Engagement'
import { EngagementAction, EngagementActionProps } from '../models/EngagementActions';
import { EngagementReport, EngagementReportType } from '../models/EngagementReport';

export interface ReadinessCheck {
  Title: string;
  Items: ReadinessCheckItem[];
}

export interface ReadinessCheckItem {
  Title: string;
  Valid: () => boolean;
  Value: any;
}

@Component
export default class EngagementClosureReadiness extends Vue {
  @Prop(Engagement) readonly engagement!: Engagement;
  @Prop(Array) readonly actions!: EngagementActionProps[];
  @Prop(Array) readonly reports!: EngagementReport[];

  get allValid () {
    return !this.readinessChecks.map(check => this.checkValid(check)).filter(c => !c).length;
  }

  get readinessChecks (): ReadinessCheck[] {
    return [
      {
        Title: 'Engagement Data',
        Items: [
          {
            Title: 'Customer name',
            Valid: () => !!this.engagement.customerName,
            Value: !this.engagement.customerName ? 'empty' : this.engagement.customerName
          },
          {
            Title: 'Engagement Start Date',
            Valid: () => !!this.engagement.creationDate,
            Value: this.engagement.creationDate
          },
          {
            Title: 'Summary',
            Valid: () => !!this.engagement.summary && this.engagement.summary.length > 200,
            Value: `${(this.engagement.summary || '').length} characters`
          },
          {
            Title: 'DevAx team',
            Valid: () => !!this.engagement.leadArchitects.length,
            Value: !this.engagement.leadArchitects.length ? 'No lead'
              : `${Object.keys(this.engagement.leadArchitects.concat(
                this.engagement.leadArchitects.concat(
                  this.engagement.leadArchitects
                )
              ).reduce((t: any, i: any) => ({ ...t, [i]: true }), {})).length} members`
          },
          {
            Title: 'Account Team',
            Valid: () => !!this.engagement.accountOwners.length,
            Value: !this.engagement.accountOwners.length ? 'No AM' : 'complete'
          },
          {
            Title: 'AWS Services',
            Valid: () => !!this.engagement.awsServices.length,
            Value: !this.engagement.awsServices.length ? 'empty'
              : `${this.engagement.awsServices.length} services`
          }
        ]
      },
    ]
  }

  get interactions () {
    return this.actions.filter(action => action.action === EngagementAction.LogInteraction)
  }

  get existingClosureReport () {
    return this.reports.filter(r => r.ReportType === EngagementReportType.CLOSURE)[0];
  }

  checkValid (check: ReadinessCheck) {
    const invalid = check.Items.map(item => item.Valid()).filter(i => !i);
    return !invalid.length;
  }

  startEngagementClosure () {
    let next = 'closure'
    if (this.existingClosureReport) {
      next = `reports/${this.existingClosureReport.ReportId}`
    }

    this.$router.push(`/engagements/${this.engagement.id}/${next}`);
  }
}
