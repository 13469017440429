import uuid from "uuid/v4";
import AuthService from "@/services/AuthService";
import S3Service from "@/services/S3Service";
import { API } from "aws-amplify";
import { apiName } from '../helpers/setupApp';

import Engagement from "@/models/Engagement";
import {
  DdbPlanningItem,
} from "@/models/DatabaseEntities";
import {
  EngagementActionProps,
  EngagementAction
} from "@/models/EngagementActions";
import { Planning } from "@/models/Planning";
import { TeamMember } from "@/models/Team";
import { ActivityParticipationMode } from "@/models/Event";
import PlanningAction from "@/models/PlanningActions";
import { EngagementReport } from '../models/EngagementReport';
import { EngagementFileMeta } from "@devax/models/engagements/Engagement";

export default class EngagementsService {
  private static instance: EngagementsService;
  private readonly auth: AuthService;
  private readonly s3: S3Service;
  private engagementList: Engagement[] = [];

  constructor() {
    this.auth = AuthService.getInstance();
    this.s3 = S3Service.getInstance();
  }

  async addFileToEngagement (Engagement: Engagement, file: File) {
    console.log("addfiletoeng");
    const FileName = file.name;
    const key = `engagements/${Engagement.id}/files/${FileName}`;
    await this.s3.putObject(key, file);

    await this.logAction(Engagement.id!, EngagementAction.AddFile, {
      FileName
    });
  }

  async initialiseFileMeta (
    args: {
      engagement: Engagement;
      fileKey: string;
      fileName: string
    }
  ) {
    const { engagement, fileKey, fileName } = args;
    const fileMeta: EngagementFileMeta = {
      engagementId: engagement.id,
      fileKey,
      description: "",
      tags: [],
      customer: engagement.customerName,
      fileName,
      owner: this.auth.user || "",
      created: new Date().toISOString(),
      updated: new Date().toISOString()
    }

    const path = 'engagements/files/' + engagement.id;
    await API.put(apiName, path, { body: fileMeta });
  }

  async deleteFileMeta (engagementId: string, fileKey: string) {
    const path = `engagements/files/${engagementId}/${encodeURIComponent(fileKey)}`;
    await API.del(apiName, path, {});
  }

  async addEngagements (engagementList: Engagement[]): Promise<void> {
    const ret = engagementList.map(item => {
      const copy: { [key: string]: any } = { ...item.getProps() };
      for (let attrName in copy) {
        const value = copy[attrName];
        if (value === "" || value === null) {
          delete copy[attrName];
        }
      }

      return copy;
    });
  }

  async addToPlanning (
    engagements: Engagement[],
    user: TeamMember,
    week: number,
    role: ActivityParticipationMode,
    AssignmentId: string = uuid()
  ): Promise<Boolean> {
    const Item: DdbPlanningItem = {
      AssignmentId,
      EngagementIds: engagements.map(e => e.id!),
      UserId: user.preferred_username,
      WeekNo: week,
      Role: role
    };

    const planning = new Planning(Item);
    return planning.$save();
  }

  async deleteEngagements (
    engagementList: Engagement[],
    force: boolean = false
  ): Promise<void> {
    // Archive engagements
    const archivedEngagements = force
      ? engagementList.map(item => ({ EngagementId: item.id }))
      : engagementList.map(item => ({ ...item, Archived: true }));
  }

  async deleteReport(engagement: Engagement, report: EngagementReport) {
    // await this.s3.deleteObject(this.engagementsDataBucket,
    //   `engagements/${engagement.id}/reports/${report.ReportId}.json`
    // );
  }

  async getById (id: string): Promise<Engagement> {
    const path = 'engagements/' + id;
    const result = await API.get(apiName, path, {});
    return new Engagement(result);
  }

  async getMultipleById (EngagementIds: string[]): Promise<Engagement[]> {
    return [];
  }

  async getEngagementActions (
    id: string
  ): Promise<EngagementActionProps[]> {
    const path = 'engagements/' + id + '/actions';
    const result = await API.get(apiName, path, {});
    return result.sort(
      (a: any, b: any) =>
        new Date(b.actionDate).getTime() - new Date(a.actionDate).getTime()
    );
  }

  async getActionById (engagementId: string, actionId: string): Promise<EngagementActionProps> {
    const path = 'engagements/' + engagementId + '/actions/' + actionId;
    const result = await API.get(apiName, path, {});
    return result;
  }

  async searchEngagementFiles (searchTerm: string): Promise<EngagementFileMeta[]> {
    const path = 'engagements/fileSearch/' + searchTerm;
    const result = await API.get(apiName, path, {});
    return result;
  }

  async getEngagementFile (fileKey: string): Promise<any> {
    const fileList = await this.s3.listObjects(fileKey);
    const fileRef = fileList[0];
    console.log('fileRef', fileRef)
    const object = await this.s3.getObject(fileRef.key);
    console.log('object', object)
    return {
      ...fileRef,
      ...object
    };
  }

  async getEngagementFiles (engagement: Engagement): Promise<any[]> {
    const fileList = await this.s3.listObjects(
      `engagements/${engagement.id}/files/`
    );
    const ret = await Promise.all(
      fileList!.map(async (fileRef: any) => {
        const object = await this.s3.getObject(
          fileRef.key
        );

        return {
          ...fileRef,
          ...object
        };
      })
    );

    return ret;
  }

  async getEngagementFileMeta (engagementId: string): Promise<EngagementFileMeta[]> {
    const path = `engagements/files/${engagementId}`;
    const result = await API.get(apiName, path, {});
    return result;
  }

  async getAllEngagementsPlanning (): Promise<Planning[]> {
    return [];
  }

  async getPlanningActions (): Promise<PlanningAction[]> {
    return [];
  }

  async getEngagementPlanning (EngagementId: string): Promise<Planning[]> {
    return [];
  }

  async getUserPlanning (UserId: string, WeekNo: number): Promise<Planning[]> {
    return [];
  }

  async saveAction(id: string, body: EngagementActionProps) {
    const path = 'engagements/' + id + '/actions';
    const result = await API.post(apiName, path, { body });
    return result;
  }

  async deleteActionById (engagementId: string, actionId: string): Promise<EngagementActionProps> {
    const path = 'engagements/' + engagementId + '/actions/' + actionId;
    const result = await API.del(apiName, path, {});
    return result;
  }

  async saveEngagement(request: Engagement) {
    const path = 'engagements';
    const result = await API.post(apiName, path, { body: request.getProps() });
    return result;
  }

  async updateEngagement(request: Engagement) {
    const path = 'engagements/' + request.id;
    const result = await API.put(apiName, path, { body: request.getProps() });
    return result;
  }

  async updateEngagementFileMeta(engagementId: string, files: EngagementFileMeta[]) {
    const path = `engagements/files/${engagementId}`;
    const updateCalls: (() => Promise<void>)[] = [];
    const results: EngagementFileMeta[] = [...files];

    files.forEach((file, index) => {
      updateCalls.push(async () => {
        results[index] = await API.put(apiName, path, { body: file });
      });
    });

    await Promise.all(updateCalls.map(updateCall => updateCall()));

    return results;
  }

  async listEngagements (): Promise<Engagement[]> {
    const path = 'engagements';
    const result = await API.get(apiName, path, {});
    const mappedRet = result as any[];
    const instances = mappedRet.map(item => new Engagement(item));
    this.engagementList = instances;
    return instances;
  }

  async listEngagementsByTeamId(teamId: string): Promise<Engagement[]> {
    const path = 'engagements/team/' + teamId;
    const result = await API.get(apiName, path, {});
    return result;
  }

  async getEngagementByOnBoardingRequest(requestId: string): Promise<Engagement> {
    const path = 'engagements/request/' + requestId;
    const result = await API.get(apiName, path, {});
    return result;
  }

  async logAction (
    engagementId: string,
    action: EngagementAction,
    actionData?: { [key: string]: any }
  ) {
    const actionId = uuid();
    const actionDate = new Date().toISOString();

    const Item: EngagementActionProps = {
      engagementId: engagementId,
      userId: this.auth.user!,
      teamId: this.auth.team!,
      action,
      id: actionId,
      actionData,
      actionDate
    };

    await this.saveAction(engagementId, Item);
  }

  static getInstance () {
    if (!EngagementsService.instance) {
      EngagementsService.instance = new EngagementsService();
    }
    return EngagementsService.instance;
  }
}
