import AuthService from "@/services/AuthService";

export enum EngagementCompetency {
  app_dev = "app_dev",
  ar_vr = "ar_vr",
  blockchain = "blockchain",
  big_data = "big_data",
  containers = "containers",
  iot = "iot",
  ml = "ml",
  ui_ux = "ui_ux",
  demo = "demo",
}

export enum UserRole {
  Leadership = "Leadership",
  EngagementManager = "Engagement Manager",
  Architect = "Team Architect"
}

export enum TeamGroup {
  LabMember = "LabMembers",
  EngagementManager = "EngagementManagers",
  Leadership = "Leadership"
}

export enum TeamCompetency {
  Analytics = "analytics",
  ArVr = "ar-vr",
  Blockchain = "blockchain",
  DataLake = "data-lake",
  Iot = "iot",
  MachineLearning = "machine-learning",
  UiUx = "ui-ux"
}

export interface CognitoUserAttributes {
  sub: string;
  email: string;
  given_name: string;
  family_name: string;
  preferred_username: string;
}

export interface CognitoTeamMemberAttributes extends CognitoUserAttributes {
  "custom:role": string;
  "custom:location": string;
  "custom:manager": string;
}

export interface TeamMemberProps extends CognitoUserAttributes {
  Username: string;
  role: string;
  competencies: EngagementCompetency[];
  sub: string;
  given_name: string;
  family_name: string;
  location: string;
  capacity: number;
  createdAt: Date;
  manager: string;
  isManager?: boolean;
}

export class TeamMember implements TeamMemberProps {
  private readonly props: TeamMemberProps;

  constructor(props: TeamMemberProps) {
    this.props = props;
  }

  get Username () {
    return this.props.Username;
  }

  get sub () {
    return this.props.sub;
  }

  get email () {
    return this.props.email;
  }

  get role () {
    return this.props.role;
  }

  set role (role: string) {
    this.props.role = role;
  }

  get competencies () {
    return this.props.competencies;
  }

  set competencies (competencies: EngagementCompetency[]) {
    this.props.competencies = competencies;
  }

  get given_name () {
    return this.props.given_name;
  }

  get family_name () {
    return this.props.family_name;
  }

  get preferred_username () {
    return this.props.preferred_username;
  }

  get location () {
    return this.props.location;
  }

  get capacity () {
    return this.props.capacity;
  }

  set capacity (capacity: number) {
    this.props.capacity = capacity;
  }

  get createdAt () {
    return this.props.createdAt;
  }

  get manager () {
    return this.props.manager;
  }

  public static canWritePlanning (): Boolean {
    return false;
  }
}
