import { CustomerOnboardingRequest } from "@devax/models/customer-onboarding/CustomerOnboardingRequest";
import { API } from "aws-amplify";
import { apiName } from "../helpers/setupApp";

export default class ProspectsService {
  private static instance: ProspectsService;

  async getProspectById(id: string): Promise<CustomerOnboardingRequest> {
    const path = "prospects/" + id;
    const result = await API.get(apiName, path, {});
    return result;
  }

  async listProspects(): Promise<CustomerOnboardingRequest[]> {
    const path = "prospects";
    const result = await API.get(apiName, path, {});
    return result;
  }

  async listProspectByTeamId(teamId: string): Promise<CustomerOnboardingRequest[]> {
    const path = "prospects/team/" + teamId;
    const result = await API.get(apiName, path, {});
    return result;
  }

  async saveProspect(body: CustomerOnboardingRequest) {
    const path = "prospects";
    const result = await API.post(apiName, path, { body });
    if (result.statusCode === "400") throw new Error();
    return result;
  }

  static getInstance() {
    if (!ProspectsService.instance) {
      ProspectsService.instance = new ProspectsService();
    }
    return ProspectsService.instance;
  }
}
