import { CustomerOnboardingRequest } from "@devax/models/customer-onboarding/CustomerOnboardingRequest";
import Engagement from "../models/Engagement";
import moment from "moment";

const engagementRequestMapper = {
  customerName: "CustomerName",
  formSubmissionDate: "CreatedAt",
  description: "EngagementOverview",
  businessSize: "BusinessSize",
  segment: "BusinessSegment",
  vertical: "BusinessVertical",
  stage: "CustomerStage",
  sfdc: "OpportunityLink",
  accountOwners: "AccountOwners",
  accountSAs: "SolutionsArchitects",
  onBoardingRequestId: "Id",
  summary: "CustomerOverview"
};

const populateEngagementWithRequest = (
  engagement: Engagement,
  request: CustomerOnboardingRequest
) => {
  Object.keys(engagement).forEach((engagementKey) => {
    // @ts-ignore:
    const requestKey = engagementRequestMapper[engagementKey];
    if (requestKey === "CreatedAt") {
      // @ts-ignore:
      engagement[engagementKey] = moment(request[requestKey]).format("YYYY-MM-DD");
    } else {
      // @ts-ignore:
      engagement[engagementKey] = request[requestKey] || engagement[engagementKey];
    }
  });
};

export default populateEngagementWithRequest;
