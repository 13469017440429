import ConfigurationService from '../services/ConfigurationService';
import { DdbPlanningAction } from './DatabaseEntities';

export enum PlanningActionType {
  AddToPlanning = 'add',
  RemoveFromPlanning = 'remove',
  ExtendPlanning = 'extend',
  SetLocation = 'set-location',
  SetProcess = 'set-process',
  SetRole = 'set-role',
  Validate = 'validate'
}

export enum PlanningActionLabels {
  add = 'New planning',
  remove = 'Delete planning',
  extend = 'Extend planning',
  set_location = 'Set Location to "{location}"',
  set_process = 'Set Process to "{process}"',
  set_role = 'Set Role to "{role}"',
  validate = 'Validate planning'
}

export interface PlanningActionProps {
  ActionId: string;
  AssignmentId: string;
  Action: PlanningActionType,
  ActionDate: Date,
  UserId: string;
  Subject: string;
  WeekNo: number;
  EngagementIds: string[];
  ActionData?: { [key: string]: any };
  Comment?: string;
}

export default class PlanningAction {
  private readonly configurationService: ConfigurationService;
  private readonly tableName: string;

  private readonly props: PlanningActionProps;

  constructor(props: PlanningActionProps) {
    this.props = props;

    this.configurationService = ConfigurationService.getInstance();
    this.tableName = this.configurationService.get('ADMIN_ENGAGEMENT_PLANNING_ACTIONS_TABLE');
  }

  get ActionId (): string {
    return this.props.ActionId;
  }

  get AssignmentId (): string {
    return this.props.AssignmentId;
  }

  get Action (): PlanningActionType {
    return this.props.Action;
  }

  get ActionDate (): Date {
    return this.props.ActionDate;
  }

  get UserId (): string {
    return this.props.UserId;
  }

  get Subject (): string {
    return this.props.Subject;
  }

  get WeekNo (): number {
    return this.props.WeekNo;
  }

  get EngagementIds (): string[] {
    return this.props.EngagementIds;
  }

  get ActionData (): { [key: string]: any } {
    return this.props.ActionData!;
  }

  async $save () {
    const item: DdbPlanningAction = {
      ...this.props,
      ActionDate: this.props.ActionDate.getTime()
    };
  }
}
