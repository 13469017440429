
import { Vue, Component, Prop } from 'vue-property-decorator'
import Engagement from '../models/Engagement'
import UserSelector from './UserSelector.vue';

@Component({
  components: {
  UserSelector
  }
  })
export default class EngagementAccountTeamSelection extends Vue {
  @Prop(Engagement) readonly engagement!: Engagement;
  @Prop(Function) readonly submit!: Function;

  onSubmit() {
    this.submit();
  }
}
