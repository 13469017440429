
import { Vue, Component } from "vue-property-decorator";
import ReactEngagementsTable from "../components/react/EngagementsTable";

@Component({
  components: {
  ReactEngagementsTable: ReactEngagementsTable as any
  }
  })
export default class EngagementManagement extends Vue {}
