
import { Vue, Component, Prop } from "vue-property-decorator";
import { EngagementInteraction } from "../../models/EngagementInteraction";
import AuthService from "../../services/AuthService";
import UserSelector from "../UserSelector.vue";

@Component({
  components: {
  UserSelector,
  },
  })
export default class InteractionTeam extends Vue {
  @Prop(Object) readonly interaction!: EngagementInteraction;
  @Prop(Function) readonly submit!: Function;

  private readonly authService: AuthService = AuthService.getInstance();

  onSubmit() {
    this.submit();
  }

  async created() {}

  async mounted() {}
}
