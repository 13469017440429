
// @ts-nocheck
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { FormData } from '@devax/models/base/forms/Form'
import DevaxFormFields from './DevAxFormFields.vue';
import { ElForm } from 'element-ui/types/form';

export enum FormLayoutMode {
  VERTICAL    = 'vertical',
  HORIZONTAL  = 'horizontal'
}

export interface FormConfiguration {
  LabelWidth?: number;
  Fields?: string[];
  LayoutMode: FormLayoutMode
}

@Component({
  components: {
  DevaxFormFields
  }
  })
export default class DevaxForm extends Vue {
  @Prop(Object) readonly value!: any;
  @Prop(Object) readonly definition!: FormData;
  @Prop(Object) readonly config!: FormConfiguration;
  @Prop(Boolean) readonly readonly!: boolean;

  get model () {
    return this.value;
  }

  set model (value: any) {
    this.$emit('input', value);
  }

  get formLayoutMode () {
    return this.config && this.config.LayoutMode
      ? this.config.LayoutMode
      : FormLayoutMode.HORIZONTAL
  }

  get formRules () {
    const fields = this.definition.Properties;
    return [];
    // return Object.keys(fields)
    //   .map(fieldName => {
    //     const field = fields[fieldName];
    //     const ret = [];

    //     // ret.push({
    //     //   type: field.Type, // TODO

    //     // })

    //     if (field.Required) {
    //       ret.push({
    //         required: true,
    //         message: this.$t('forms.validation.field.required', field),
    //         trigger: 'change'
    //       });
    //     }

    //     if (field.Min || field.Max) {
    //       ret.push({
    //         min: field.Min,
    //         max: field.Max,
    //         message: this.$t('forms.validation.field.num_length', field),
    //         trigger: 'change'
    //       });
    //     }

    //     if (field.Pattern) {
    //       ret.push({
    //         pattern: field.Pattern,
    //         message: this.$t('forms.validation.pattern', field),
    //         trigger: 'change'
    //       });
    //     }

    //     // TODO Other validators

    //     return {
    //       [fieldName]: ret
    //     }
    //   }).reduce((t, i) => ({ ...t, ...i }), {});
  }

  get labelWidth () {
    return `${(this.config || {}).LabelWidth || 150}px`;
  }

  async isValid () {
    const form = this.$refs.form as ElForm;
    const valid = await form.validate();
    return valid;
  }

  async created () {}

  async mounted () {}

  @Watch('value', { immediate: true, deep: true })
  async onValueChanged (value: any) {
    if (value) {
      this.$emit('input', value);
    }
  }
}
