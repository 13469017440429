
import { Vue, Component, Watch } from "vue-property-decorator";
import {
  EngagementInteraction,
  InteractionAttendeeRole,
  InteractionType
} from "../models/EngagementInteraction";
import EngagementsService from "../services/EngagementsService";
import ConfigurationService from "../services/ConfigurationService";
import Engagement, { Phase } from "../models/Engagement";
import InteractionTeam from "../components/interaction/InteractionTeam.vue";
import {
  EngagementAction,
  EngagementActionProps
} from "../models/EngagementActions";
import uuid from "uuid";
import AuthService from "../services/AuthService";
import FrameworkService from "../services/FrameworkService";
import enumToOptions from "../helpers/enumToOptions";

@Component({
  components: {
  InteractionTeam
  }
  })
export default class EngagementInteractionLogger extends Vue {
  private readonly authService: AuthService = AuthService.getInstance();
  private readonly configService: ConfigurationService = ConfigurationService.getInstance();

  private readonly engagementsService: EngagementsService = EngagementsService.getInstance();

  private readonly fwkService: FrameworkService = FrameworkService.getInstance();

  public engagementId?: string;
  public engagement: Engagement | null = null;

  public interaction: EngagementInteraction | null = null;
  public engagementAction: EngagementActionProps | null = null;
  public preview = true;

  private interactionSaverTimeout: any = 0;

  public readonly editorOptions: any = {
    // lineNumbers: true,
    // styleActiveLine: true,
    // styleSelectedText: true,
    // lineWrapping: true,
    // indentWithTabs: true,
    // tabSize: 2,
    // indentUnit: 2
  };

  toggleEditor() {
    this.preview = !this.preview;
  }

  get availablePhases() {
    return enumToOptions(Phase);
  }

  get availableRoles() {
    return enumToOptions(InteractionAttendeeRole);
  }

  get availableTypes() {
    return enumToOptions(InteractionType);
  }

  get userImage() {
    return this.engagementAction
      ? `${this.configService.get("EMPLOYEE_PICTURE_URL")}?uid=${
          this.engagementAction?.userId
        }`
      : "";
  }

  async deleteInteraction() {
    await this.engagementsService.deleteActionById(
      this.engagementId!,
      this.engagementAction!.id
    );
    this.fwkService.addAlert("success", `Successfully deleted interaction`);
  }

  async created() {
    this.engagementId = this.$route.params.engagement!;
    await this.fetchData();

    const actionId = this.$route.params.action;
    if (actionId) {
      await this.fetchAction(actionId);
      this.interaction = this.engagementAction!
        .actionData as EngagementInteraction;
    } else {
      this.preview = false;
      this.interaction = {
        phase: Phase.PreQualification,
        title: "",
        type: InteractionType.Workshop,
        date: new Date()
          .toISOString()
          .split("T")
          .shift() as string,
        location: "",
        attendeeNo: 0,
        attendeeRoles: [],
        participants: [],
        details: `## Raw notes

Feel free to log anything you want here during the interaction.

## Customer quotes

Insert your content here

## Opportunities

Insert your content here

## Highlights

Insert your content here

## Lowlights

Insert your content here

## Follow-ups

Insert your content here

## Metadata

Insert your metadata here, i.e. AccountIDs, PFRs, Betas
        `
      };
    }
  }

  async fetchAction(actionId: string) {
    this.engagementAction = await this.engagementsService.getActionById(
      this.engagementId!,
      actionId
    );
  }

  async fetchData() {
    const engagement = await this.engagementsService.getById(
      this.engagementId!
    );
    this.engagement = engagement;
  }

  async mounted() {}

  async logInitialInteraction() {
    const actionId = this.engagementAction ? this.engagementAction.id : uuid();
    const actionDate = this.engagementAction
      ? this.engagementAction.actionDate
      : new Date().toISOString();
    const action: EngagementAction = EngagementAction.LogInteraction;

    this.engagementAction = {
      engagementId: this.engagement!.id!,
      userId: this.engagementAction?.userId || this.authService.user!,
      teamId: this.engagement?.teamId!,
      action,
      id: actionId,
      actionDate
    };

    await this.saveInteraction();
  }

  async saveInteraction() {
    this.engagementAction!.actionData = this.interaction!;
    await this.engagementsService.saveAction(
      this.engagementId!,
      this.engagementAction!
    );
    FrameworkService.getInstance().addAlert("success", "Interaction saved");

    await this.engagementsService.updateEngagement(this.engagement!);
    FrameworkService.getInstance().addAlert("success", "Engagement updated");
  }
}
