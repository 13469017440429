
import moment from 'moment';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Message } from '@/models/Messaging';

import AuthService from '@/services/AuthService';

@Component({
  filters: {
  age (value: string | number | Date) {
  return moment(value).fromNow();
  }
  }
  })
export default class Messaging extends Vue {
  @Prop(Array) readonly messages!: Message[];

  private readonly authService: AuthService;

  constructor () {
    super();

    this.authService = AuthService.getInstance();
  }

  messageIsMine (message: Message) {
    return message.Source === this.authService.user;
  }
}
