import React, { useState, useEffect } from "react";
import {
  Table,
  TableProps,
  Header,
  TextFilter,
  Button,
  Select,
  Multiselect,
  MultiselectProps,
  SelectProps,
  SpaceBetween
} from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import Engagement, { RagStatus, Phase } from "../../models/Engagement";
import EngagementsService from "../../services/EngagementsService";
import ConfigurationService from "../../services/ConfigurationService";
import TeamsService from "../../services/TeamsService";
import AuthService from "@/services/AuthService";
import { EngagementAction, EngagementActionProps } from "../../models/EngagementActions";
import { reactGlobal } from "../../main";

type EngagementWithInteraction = Engagement & {
  lastInteraction?: EngagementActionProps | null;
};

const anyOption = { label: "Any Team", value: "any" };
const phaseStorageKey = "selectedPhases";
const segmentStorageKey = "selectedSegments";
const ragStorageKey = "selectedRAGs";

function EngagementsTable() {
  const [loading, setLoading] = useState(true);
  const [engagements, setEngagements] = useState<EngagementWithInteraction[]>([]);
  const [selectedTeamOption, setSelectedTeamOption] = useState<SelectProps.Option>();
  const [teamOptions, setTeamOption] = useState<SelectProps.Options>([]);
  const [selectedPhases, setSelectedPhases] = React.useState<MultiselectProps.Options>([]);
  const [selectedSegments, setSelectedSegments] = React.useState<MultiselectProps.Options>([]);
  const [selectedRAGs, setSelectedRAGs] = React.useState<MultiselectProps.Options>([]);
  const [phaseOptions, setPhaseOptions] = React.useState<MultiselectProps.Options>([]);
  const [segmentOptions, setSegmentOptions] = React.useState<MultiselectProps.Options>([]);
  const [ragOptions, setRagOptions] = React.useState<MultiselectProps.Options>([]);
  const columnDefinitions: TableProps.ColumnDefinition<EngagementWithInteraction>[] = generateColumnDefinitions(
    ConfigurationService.getInstance().get("EMPLOYEE_PICTURE_URL"),
    EngagementsService.getInstance()
  );

  useEffect(() => {
    const storedSelectedPhases = localStorage.getItem(phaseStorageKey);
    const storedSelectedSegments = localStorage.getItem(segmentStorageKey);
    const storedSelectedRAGs = localStorage.getItem(ragStorageKey);
    if (storedSelectedPhases) setSelectedPhases(JSON.parse(storedSelectedPhases));
    if (storedSelectedSegments) setSelectedSegments(JSON.parse(storedSelectedSegments));
    if (storedSelectedRAGs) setSelectedRAGs(JSON.parse(storedSelectedRAGs));
  }, []);
  useEffect(() => {
    localStorage.setItem(phaseStorageKey, JSON.stringify(selectedPhases));
  }, [selectedPhases]);
  useEffect(() => {
    localStorage.setItem(segmentStorageKey, JSON.stringify(selectedSegments));
  }, [selectedSegments]);
  useEffect(() => {
    localStorage.setItem(ragStorageKey, JSON.stringify(selectedRAGs));
  }, [selectedRAGs]);

  const setFilterOptions = (engagements: Engagement[]) => {
    const phases = [...new Set(engagements.map(({ phase }) => phase).filter(Boolean))];
    const segments = [...new Set(engagements.map(({ segment }) => segment).filter(Boolean))];
    const rags = [...new Set(engagements.map(({ ragStatus }) => ragStatus).filter(Boolean))];
    setPhaseOptions(phases.map(phase => ({ label: phase, value: phase })).sort((a, b) => phaseSortingComparator(a.label, b.label)));
    setSegmentOptions(segments.map(segment => ({ label: segment, value: segment })).sort((a: any, b: any) => a.label.localeCompare(b.label)));
    setRagOptions(rags.map(rags => ({ label: rags, value: rags })).sort((a, b) => ragSortingComparator(a.label, b.label)));
    return engagements;
  };

  useEffect(() => {
    setLoading(true);
    selectedTeamOption &&
      EngagementsService.getInstance()
        .listEngagementsByTeamId(selectedTeamOption.value!)
        .then(setFilterOptions)
        .then(setEngagements)
        .catch()
        .finally(() => setLoading(false));
  }, [selectedTeamOption]);

  useEffect(() => {
    TeamsService.getInstance()
      .listTeams()
      .then(teams => {
        const teamOptions = teams.map(({ id, name }) => ({
          label: name || id,
          value: id
        }));
        teamOptions.unshift(anyOption);
        setTeamOption(teamOptions);
        const defaultTeam = AuthService.getInstance().team;
        setSelectedTeamOption(teamOptions.find(({ value }) => value === defaultTeam) || anyOption);
      })
      .catch();
  }, []);

  const { items, actions, filteredItemsCount, collectionProps, filterProps } = useCollection(engagements, {
    filtering: {
      filteringFunction: (item, filterText) => {
        return (
          !!item.customerName.match(new RegExp(filterText, "ig")) &&
          !!(!selectedPhases.length || selectedPhases.find(({ value }: any) => item.phase === value)) &&
          !!(!selectedSegments.length || selectedSegments.find(({ value }: any) => item.segment === value)) &&
          !!(!selectedRAGs.length || selectedRAGs.find(({ value }: any) => item.ragStatus === value))
        );
      }
    },
    sorting: {
      defaultState: {
        sortingColumn: columnDefinitions[1],
        isDescending: true
      }
    },
    selection: {}
  });

  return (
    // @ts-ignore
    <Table
      {...collectionProps}
      columnDefinitions={columnDefinitions}
      items={items}
      sortingDisabled={false}
      loading={loading}
      loadingText="loading engagements"
      wrapLines={false}
      header={
        <Header
          counter={`(${filteredItemsCount})`}
          actions={
            <SpaceBetween size="xs" direction="horizontal">
              <div style={styles.teamSelect}>
                {/* @ts-ignore */}
                <Select
                  selectedOption={selectedTeamOption || anyOption}
                  options={teamOptions}
                  onChange={({ detail }) => setSelectedTeamOption(detail.selectedOption)}
                />
              </div>
              {/* @ts-ignore */}
              <Button variant="primary" iconName="add-plus" onClick={() => reactGlobal.router.push("/engagements/new")}>
                New Engagement
              </Button>
            </SpaceBetween>
          }
        >
          Engagements
        </Header>
      }
      filter={
        <SpaceBetween size="xs" direction="horizontal">
          <div style={styles.searchFilter}>
            {/* @ts-ignore */}
            <TextFilter {...filterProps} filteringPlaceholder="search for customer" />
          </div>
          <div style={styles.selectFilter}>
            {/* @ts-ignore */}
            <Multiselect
              selectedOptions={selectedPhases}
              onChange={({ detail }) => setSelectedPhases(detail.selectedOptions)}
              options={phaseOptions}
              placeholder="choose phases"
            />
          </div>
          <div style={styles.selectFilter}>
            {/* @ts-ignore */}
            <Multiselect
              selectedOptions={selectedSegments}
              onChange={({ detail }) => setSelectedSegments(detail.selectedOptions)}
              options={segmentOptions}
              placeholder="choose segments"
            />
          </div>
          <div style={styles.selectFilter}>
            {/* @ts-ignore */}
            <Multiselect
              selectedOptions={selectedRAGs}
              onChange={({ detail }) => setSelectedRAGs(detail.selectedOptions)}
              options={ragOptions}
              placeholder="choose RAGs"
            />
          </div>
        </SpaceBetween>
      }
    />
  );
}

const sortWeight = {
  RagStatus: {
    [RagStatus.Green]: 1,
    [RagStatus.Amber]: 2,
    [RagStatus.Red]: 3,
    [RagStatus.Blue]: 4
  },
  Phase: {
    [Phase.PreQualification]: 1,
    [Phase.UnderQualification]: 2,
    [Phase.Discovery]: 3,
    [Phase.Build]: 4,
    [Phase.Complete]: 5,
    [Phase.Disengaged]: 6,
    [Phase.Rejected]: 7
  }
};

const ragSortingComparator = (a?: RagStatus, b?: RagStatus) => {
  const first = a ? sortWeight.RagStatus[a] : Infinity;
  const second = b ? sortWeight.RagStatus[b] : Infinity;
  return first - second;
};
const engagementRagSortingComparator = (a: Engagement, b: Engagement) => ragSortingComparator(a.ragStatus, b.ragStatus);

const phaseSortingComparator = (a?: Phase, b?: Phase) => {
  const first = a ? sortWeight.Phase[a] : Infinity;
  const second = b ? sortWeight.Phase[b] : Infinity;
  return first - second;
};
const engagementPhaseSortingComparator = (a: Engagement, b: Engagement) => phaseSortingComparator(a.phase, b.phase);

const lastInteractionSortingComparator = (a: EngagementWithInteraction, b: EngagementWithInteraction) => {
  const aDate = a.lastInteraction?.actionData?.date || a.lastInteraction?.actionDate;
  const bDate = b.lastInteraction?.actionData?.date || b.lastInteraction?.actionDate;

  if (!aDate) return -Infinity;
  if (!bDate) return Infinity;

  return +new Date(aDate) - +new Date(bDate);
};

const generateColumnDefinitions = (avatarUrl: string, engagementsService: EngagementsService) => [
  {
    id: "team",
    header: "Team",
    cell: (item: EngagementWithInteraction) => item.teamId
  },
  {
    id: "date",
    header: "Creation Date",
    cell: (item: EngagementWithInteraction) => (!item.creationDate ? "N/D" : new Date(item.creationDate).toDateString()),
    sortingField: "creationDate"
  },
  {
    id: "phase",
    header: "Phase",
    cell: (item: EngagementWithInteraction) => item.phase || "",
    sortingComparator: engagementPhaseSortingComparator
  },
  {
    id: "customer",
    header: "Customer",
    cell: (item: EngagementWithInteraction) => <a href={`/engagements/${item.id}`}>{item.customerName || "open"}</a>,
    sortingField: "customerName"
  },
  {
    id: "segment",
    header: "Segment",
    cell: (item: EngagementWithInteraction) => item.segment || "",
    sortingField: "segment"
  },
  {
    id: "rag",
    header: "RAG",
    cell: (item: EngagementWithInteraction) => item.ragStatus || "",
    sortingComparator: engagementRagSortingComparator
  },
  {
    id: "architects",
    header: "Architects",
    cell: (item: EngagementWithInteraction) => (
      <div>
        {item.leadArchitects.map(archId => (
          <img style={styles.avatar} src={`${avatarUrl}?uid=${archId}`} key={archId} />
        ))}
        {item.supportArchitects.map(archId => (
          <img style={styles.avatar} src={`${avatarUrl}?uid=${archId}`} key={archId} />
        ))}
        {item.shadowArchitects.map(archId => (
          <img style={styles.avatar} src={`${avatarUrl}?uid=${archId}`} key={archId} />
        ))}
      </div>
    )
  },
  {
    id: "interaction",
    header: "Last Interaction",
    cell: (item: EngagementWithInteraction) => {
      if (!item) return "";
      if (item.lastInteraction === null) return "";
      if (item.lastInteraction) {
        const interaction = item.lastInteraction;
        return (
          <a href={`/engagements/${item.id}/log/${interaction.id}`}>
            {`${new Date(interaction.actionData?.date || interaction.actionDate).toDateString()}`}
          </a>
        );
      }
      const id = "a-" + item.id;
      engagementsService.getEngagementActions(item.id).then(actions => {
        const [interaction] = actions
          .filter((a: any) => a.action === EngagementAction.LogInteraction)
          .sort((a: any, b: any) => a.actionDate - b.actionDate);
        const element = (document as any).getElementById(id);
        if (!element) return;
        if (interaction) {
          item.lastInteraction = interaction;
          element.textContent = `${new Date(interaction.actionData?.date || interaction.actionDate).toDateString()}`;
          element.href = `/engagements/${item.id}/log/${interaction.id}`;
        } else {
          item.lastInteraction = null;
          element.textContent = "";
        }
      });
      return <a id={id}>loading...</a>;
    },
    sortingComparator: lastInteractionSortingComparator
  }
];

const styles = {
  avatar: {
    marginRight: "5px",
    width: "25px",
    height: "25px",
    borderRadius: "100%"
  },
  teamSelect: {
    width: "200px"
  },
  searchFilter: {
    width: "300px"
  },
  selectFilter: {
    width: "180px"
  }
};

export default EngagementsTable;
