import { v4 as uuid } from "uuid";
import {
  ActivityParticipationMode,
  ActivityParticipationProcess,
  ActivityParticipationLocation
} from "./Event";
import ConfigurationService from "@/services/ConfigurationService";
import { TeamMember, UserRole } from "./Team";
import { DdbPlanningItem } from "./DatabaseEntities";
import FrameworkService from "@/services/FrameworkService";
import PlanningAction, { PlanningActionType } from "./PlanningActions";
import AuthService from "@/services/AuthService";

export interface PlanningItem {
  AssignmentId?: string;
  User?: TeamMember;
  UserId: string;
  EngagementIds: string[];
  WeekNo: number;
  Role: ActivityParticipationMode;
  Process?: ActivityParticipationProcess;
  Location?: ActivityParticipationLocation;
  Comment?: string;

  ReportedToTracking: boolean;
}

export interface ToolBoxTool {
  id: string;
  icon: string;
  name: string;
  description: string;
  // TODO
}

export class Planning {
  private readonly authService: AuthService;
  private readonly configService: ConfigurationService;
  private readonly fwkService: FrameworkService;

  public $active: boolean = false;

  private readonly props: PlanningItem;

  constructor(props: DdbPlanningItem) {
    this.authService = AuthService.getInstance();
    this.configService = ConfigurationService.getInstance();
    this.fwkService = FrameworkService.getInstance();

    this.props = props as PlanningItem;
  }

  get AssignmentId() {
    return this.props.AssignmentId;
  }

  get UserId() {
    return this.props.UserId;
  }

  set UserId(user: string) {
    this.props.UserId = user;
  }

  get EngagementIds() {
    return this.props.EngagementIds;
  }

  get WeekNo() {
    return this.props.WeekNo;
  }

  set WeekNo(n: number) {
    this.props.WeekNo = n;
  }

  get Comment() {
    if (this.props.Comment === undefined) {
      return "";
    }
    return this.props.Comment;
  }

  set Comment(comment: string) {
    this.props.Comment = comment;
  }

  get Role() {
    return this.props.Role;
  }

  set Role(role: ActivityParticipationMode) {
    this.props.Role = role;
  }

  get Process() {
    return this.props.Process!;
  }

  set Process(process: ActivityParticipationProcess) {
    this.props.Process = process;
  }

  get Location() {
    return this.props.Location!;
  }

  set Location(location: ActivityParticipationLocation) {
    this.props.Location = location;
  }

  get ReportedToTracking() {
    return this.props.ReportedToTracking!;
  }

  set ReportedToTracking(reported: boolean) {
    this.props.ReportedToTracking = reported;
  }

  public ensureCanWritePlanning(): Boolean {
    // a planning item can be written only by the owner or by an upper level
    if (this.UserId === this.authService.user) {
      console.log("user allowed to write for himself");
      return true;
    }

    console.log("trying to write as", this.authService.user);

    // if not for himself check if has permissions
    if (!TeamMember.canWritePlanning()) {
      console.debug("cannot write to planning");
      return false;
    } else return true; // admin
  }

  async $remove(): Promise<Boolean> {
    if (!this.ensureCanWritePlanning()) {
      return false;
    }
    const TableName = this.configService.get("ADMIN_ENGAGEMENT_PLANNING_TABLE");
    if (!this.props.AssignmentId) throw new Error("Item does not exist on DB");
    const AssignmentId = this.props.AssignmentId;

    const action = new PlanningAction({
      ActionId: uuid(),
      Action: PlanningActionType.RemoveFromPlanning,
      ActionDate: new Date(),
      AssignmentId: this.props.AssignmentId!,
      EngagementIds: this.props.EngagementIds,
      Subject: this.props.UserId,
      UserId: this.authService.user!,
      WeekNo: this.props.WeekNo,
      Comment: this.props.Comment
    });

    await action.$save();
    return true;
  }

  async $save(
    actionType: PlanningActionType = PlanningActionType.AddToPlanning,
    data?: { [key: string]: any }
  ): Promise<Boolean> {
    if (!this.ensureCanWritePlanning()) {
      return false;
    }

    const TableName = this.configService.get("ADMIN_ENGAGEMENT_PLANNING_TABLE");
    if (!this.props.AssignmentId) this.props.AssignmentId = uuid();
    const AssignmentId = this.props.AssignmentId;

    const props = { ...this.props };

    delete props.User;

    console.log("saving to ddb", this);

    const action = new PlanningAction({
      ActionId: uuid(),
      Action: actionType,
      ActionDate: new Date(),
      ActionData: data,
      AssignmentId: props.AssignmentId!,
      EngagementIds: props.EngagementIds,
      Subject: props.UserId,
      UserId: this.authService.user!,
      WeekNo: props.WeekNo,
      Comment: props.Comment
    });

    await action.$save();

    this.fwkService.eventBus.$emit("planning-changed");

    return true;
  }
}
