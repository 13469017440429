import { API } from "aws-amplify";
import { apiName } from '../helpers/setupApp';

export type Team = {
  id: string,
  name: string,
  notificationUrl: string,
  acceptRequests: boolean
}

export default class TeamsService {
  private static instance: TeamsService;

  async getTeamById(id: string): Promise<Team> {
    const path = 'teams/' + id;
    const result = await API.get(apiName, path, {});
    return result;
  }

  async listTeams(): Promise<Team[]> {
    const path = 'teams';
    const result = await API.get(apiName, path, {});
    return result;
  }

  async updateTeam(id: string, body: Team) {
    const path = 'teams/' + id;
    const result = await API.put(apiName, path, { body });
    return result;
  }

  static getInstance () {
    if (!TeamsService.instance) {
      TeamsService.instance = new TeamsService();
    }
    return TeamsService.instance;
  }
}
