let config: any = {
  ENVIRONMENT: undefined,
  AWS_ACCOUNT_ID: undefined,
  AWS_REGION: undefined,
  USER_POOL_ID: undefined,
  USER_POOL_CLIENT_ID: undefined,
  IDENTITY_POOL_ID: undefined,
  COGNITO_CUSTOM_DOMAIN: undefined,
  API_ENDPOINT: undefined,
  TEAM_API_URL: undefined,
  EMPLOYEE_PICTURE_URL: 'https://internal-cdn.amazon.com/badgephotos.amazon.com/',
  ADMIN_ENGAGEMENT_ACTIONS_TABLE: 'none',
  ADMIN_ENGAGEMENT_PLANNING_ACTIONS_TABLE: 'none',
  ADMIN_ENGAGEMENT_PLANNING_TABLE: 'none',
  ENGAGEMENTS_DATA_BUCKET: undefined
};

export async function fetchConfig(url = '/config.json') {
  if (!config.API_URL) {
    try {
      let response = await fetch(url);
      config = await response.json();
      return { config: config };
    } catch (e) {
      console.error(`error loading json ${e}`);
    }
  } else {
    return config;
  }
}
