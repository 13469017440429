import React, { useEffect, useState } from "react";
import { Container, Header, Button } from "@amzn/awsui-components-react";
import FileDrop from "./FileDrop";
import EngagementFileModal from "./EngagementFileModal";
import Engagement from "@/models/Engagement";
import S3Service from "../../services/S3Service";
import EngagementsService from "../../services/EngagementsService";
import AuthService from "../../services/AuthService";
import "./EngagementFiles.scss";

const fileTypesAllowed = ["xls", "png", "psd", "doc", "docx", "ai", "pdf", "jpg", "jpeg"];
const maxAllowed = 20;

interface Props {
  engagement: Engagement;
  s3Service: S3Service;
  engagementsService: EngagementsService;
  confirm: (message: string) => void;
  addAlert: (type: string, message: string) => void;
}

export const getFilenameFromKey = (fileKey: string) => {
  if (typeof fileKey !== "string") return undefined;
  return fileKey.split("/").pop();
};

const getFileType = (file: any) => {
  const extension: string = file.key.split(".").pop();

  switch (extension.toLocaleLowerCase()) {
    case "png":
    case "jpg":
    case "jpeg":
      return "image";
    case "xls":
    case "xlsx":
      return "excel";
    case "yaml":
    case "json":
    case "js":
    case "ts":
    case "py":
    case "java":
      return "code";
  }

  return extension;
};

export const renderFilePreview = (file: any) => {
  const fileType = getFileType(file);

  if (fileType === "image") {
    return <img className="image" src={URL.createObjectURL(file.Body)} />;
  }

  return (
    <span className="icon">
      {(() => {
        switch (fileType) {
          case "code":
            return <i className="far fa-file-code text-primary" />;
          case "excel":
            return <i className="far fa-file-excel text-success" />;
          case "pdf":
            return <i className="far fa-file-pdf text-danger" />;
          default:
            return <i className="far fa-file-alt" />;
        }
      })()}
    </span>
  );
};

export default function EngagementFiles(props: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [engagementFiles, setEngagementFiles] = useState<any[]>([]);
  const [openAtIndex, setOpenAtIndex] = useState<number | undefined>(undefined);
  const prefix = `engagements/${props.engagement.id}/files/`;

  const fetchData = async () => {
    const files = await props.engagementsService.getEngagementFiles(props.engagement);
    files.sort((a, b) => a.LastModified - b.LastModified);
    setEngagementFiles(files);
    return files;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteFile = async (file: any) => {
    try {
      await props.confirm("Do you really want to delete the file?");
      await props.s3Service.deleteObject(file.key);
      await props.engagementsService.deleteFileMeta(props.engagement.id, file.key);
      props.addAlert("success", "Successfully deleted file");
      await fetchData();
    } catch (e) {}
  };

  const uploadFile = async (file: File) => {
    if (file.size > 100000000) {
      const errorMsg = "File Size should not exceed 100MB";
      props.addAlert("error", errorMsg);
      throw errorMsg;
    }
    const fileKey = `${prefix}${file.name}`;
    const fileName = file.name;
    const metadata: any = {
      Principal: AuthService.getInstance().user!,
    };
    await props.s3Service.putObject(fileKey, file, metadata);
    await props.engagementsService.initialiseFileMeta({
      engagement: props.engagement,
      fileKey,
      fileName,
    });
  };

  const onDropFiles = (files: FileList) => {
    const prevLength = engagementFiles.length;
    const newFiles = Array.from(files);
    if (!newFiles.length) return;
    if (prevLength + newFiles.length > maxAllowed) return void props.addAlert("error", `maximum ${maxAllowed} files`);
    for (const newFile of newFiles) {
      const isAllowed = fileTypesAllowed.some((type) => newFile.name.endsWith(`.${type}`));
      if (!isAllowed) return void props.addAlert("error", "File type not Allowed");
    }
    Promise.all(newFiles.map(async (file) => uploadFile(file)))
      .then(async (_data) => {
        fetchData().then((newFetchedFiles) => {
          if (newFetchedFiles.length === engagementFiles.length) {
            props.addAlert("info", "These files have already been uploaded");
            return;
          }
          const fileNameSet = new Set<string>();
          newFiles.forEach((newFile) => {
            fileNameSet.add(newFile.name);
          });
          for (let i = 0; i < newFetchedFiles.length; i++) {
            if (fileNameSet.has(getFilenameFromKey(newFetchedFiles[i].key) || "")) {
              setOpenAtIndex(i);
              setModalOpen(true);
              break;
            }
          }
        });
      })
      .catch((error) => {
        props.addAlert("error", "Error processing files");
        throw error;
      });
  };

  return (
    <div id="engagement-files">
      <div className="container">
        <Container
          header={
            <Header
              variant="h2"
              description="Files and resources about the engagement"
              actions={
                // @ts-ignore
                <Button
                  variant="primary"
                  disabled={!engagementFiles.length}
                  onClick={() => {
                    setOpenAtIndex(0);
                    setModalOpen(true);
                  }}
                >
                  Edit
                </Button>
              }
            >
              Engagement files
            </Header>
          }
          footer={
            <div className="files">
              {engagementFiles.map((file, index) => (
                <div className="file" key={index}>
                  <div
                    className="preview"
                    onClick={() => {
                      setOpenAtIndex(index);
                      setModalOpen(true);
                    }}
                  >
                    {renderFilePreview(file)}
                  </div>
                  <div className="name">{getFilenameFromKey(file.key)}</div>
                  {/* @ts-ignore */}
                  <Button className="button" onClick={() => deleteFile(file)}>
                    Delete
                  </Button>
                </div>
              ))}
              <div className="grower" />
            </div>
          }
        >
          <FileDrop
            engagement={props.engagement}
            engagementFiles={engagementFiles}
            setEngagementFiles={setEngagementFiles}
            onDropFiles={onDropFiles}
            fileTypesAllowed={fileTypesAllowed}
          />
        </Container>
      </div>
      <EngagementFileModal
        open={modalOpen}
        openAtIndex={openAtIndex}
        files={engagementFiles}
        onClose={() => {
          setOpenAtIndex(undefined);
          setModalOpen(false);
        }}
        engagement={props.engagement}
        engagementsService={props.engagementsService}
        addAlert={props.addAlert}
        s3Service={props.s3Service}
      />
    </div>
  );
}
