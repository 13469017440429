
import { Vue, Component, Prop } from 'vue-property-decorator'
import Engagement from '../../models/Engagement';
import EngagementsService from '../../services/EngagementsService';
import { Planning } from '../../models/Planning';

@Component
export default class TeamInvestment extends Vue {
  @Prop(Engagement) readonly engagement!: Engagement;

  private readonly engagementsService: EngagementsService = EngagementsService.getInstance();

  public planningItems: Planning[] = [];

  async created () {
    await this.fetchData();
  }

  async mounted () {}

  async fetchData () {
    this.planningItems = await this.engagementsService.getEngagementPlanning(this.engagement.id!);
  }
}
