
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { FormDataInput, FormFieldType } from '@devax/models/base/forms/Form';
import UserSelector from '../UserSelector.vue';
import { NoCache } from '../../decorators';

@Component({
  components: {
  UserSelector
  }
  })
export default class DevaxFormFieldInput extends Vue {
  @Prop() readonly value!: any;
  @Prop(Object) readonly field!: FormDataInput;
  @Prop(Boolean) readonly fromArray!: boolean;
  @Prop(Boolean) readonly readonly!: boolean;

  public booleanValue = this.value;

  get fieldOptions () {
    return (this.field.Options || [])
      .map(option => ({
        label: option.Label,
        id: option.Value
      }))
  }

  get fieldType () {
    const existingType = this.field.FieldType;
    if (existingType) return existingType;

    const type = this.fromArray ? this.field.ArrayType : this.field.Type;

    switch (type) {
      case 'string':
      case undefined:
        return FormFieldType.TEXT;
      case 'number':
      case 'integer':
      case 'float':
      case 'double':
        return FormFieldType.NUMBER;
      case 'date':
        return FormFieldType.DATE;
      case 'boolean':
        return FormFieldType.SWITCH;
      default:
        return 'text'
    }
  }

  get fieldValue () {
    return this.value;
  }

  set fieldValue (value: any) {
    this.$emit('input', value);
    this.$forceUpdate();
  }

  @Watch('booleanValue', { immediate: true })
  onValueChanged(val: any) {
    if (val !== undefined) {
      this.fieldValue = val
    }
  }
}
