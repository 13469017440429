
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class AwsAccountIdsInput extends Vue {
  @Prop(Array) readonly value!: string[];

  public accountIds: string = this.value?.join("\n") || "";

  @Watch("accountIds", { immediate: true })
  onManualChange(val: string) {
    if (val && this.value.join("\n") !== val) {
      const accounts = val.split("\n");
      const valid = accounts.filter(account =>
        account.match(/(\d{4}-?\d{4}-?\d{4})/)
      );
      this.$emit("input", valid);
    }
  }
}
