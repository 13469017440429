
import { Vue, Component } from "vue-property-decorator";
import PublicLandingBanner from "../components/PublicLandingBanner.vue";
import TeamService from "../services/TeamsService";

@Component({
  components: {
  PublicLandingBanner
  }
  })
export default class ComponentClassName extends Vue {
  private teams: any[] = [];
  private cardDefinition = {
    header: (team: any) => `<h2>${team.name || team.id}</h2>`,
    sections: [
      {
        id: "link",
        content: (team: any) =>
          `<a href="/customer-onboarding?team=${team.id}" >Make a Request</a>`
      }
    ]
  };

  async created() {
    const teams = await TeamService.getInstance().listTeams();
    this.teams = teams.filter(team => team.acceptRequests);
  }

  async mounted() {}
}
