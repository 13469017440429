
import moment from "moment";
import { Vue, Component, Watch } from "vue-property-decorator";
// @ts-ignore
import { ReactInVue } from "vuera";

import Banner from "@/components/Banner.vue";
import EngagementDetailsForm from "@/components/engagements/EngagementDetailsForm.vue";
import EngagementTechnicalForm from "@/components/engagements/EngagementTechnicalForm.vue";
import EngagementTeamInvestment from "@/components/engagements/EngagementTeamInvestment.vue";
import EngagementFiles from "../components/engagementFiles";
import EngagementAccountTeamSelection from "../components/EngagementAccountTeamSelection.vue";

import Engagement, { Phase } from "@/models/Engagement";
import {
  EngagementActionProps,
  EngagementAction,
} from "@/models/EngagementActions";
import { Message } from "@/models/Messaging";

import AuthService from "@/services/AuthService";
import EngagementsService from "@/services/EngagementsService";
import ProspectsService from "@/services/ProspectsService";
import FrameworkService from "@/services/FrameworkService";
import { AWSService } from "@/models/AWSServices";
import S3Service from "../services/S3Service";
import EngagementInteractionList from "../components/EngagementInteractionList.vue";
import EngagementClosureReadiness from "../components/EngagementClosureReadiness.vue";
import EngagementTeam from "../components/engagements/EngagementTeam.vue";
import EngagementReportList from "../components/EngagementReportList.vue";
import Messaging from "../components/Messaging.vue";
import enumToOptions from "../helpers/enumToOptions";
import populateEngagementWithRequest from "../helpers/populateEngagementWithRequest";

enum EngagementViewActionType {
  button = "button",
  upload = "upload",
}

interface EngagementViewAction {
  id: string;
  icon: string;
  label: string;
  style: string;
  type: EngagementViewActionType;
}

@Component({
  components: {
  Banner,
  'engagement-files': ReactInVue(EngagementFiles),
  EngagementAccountTeamSelection,
  EngagementClosureReadiness,
  EngagementDetailsForm,
  EngagementInteractionList,
  EngagementReportList,
  EngagementTechnicalForm,
  EngagementTeamInvestment,
  EngagementTeam,
  Messaging,
  },
  filters: {
  base64(val: any) {
  const parsed = String.fromCharCode.apply(null, val);
  const base64 = btoa(parsed);
  return `data:image/png;base64,${base64}`;
  },
  },
  })
export default class EngagementDetails extends Vue {
  private readonly authService: AuthService;
  private readonly engagementsService: EngagementsService;
  private readonly fwkService: FrameworkService;
  private readonly s3Service: S3Service;

  private engagement: Engagement | null;
  private engagementId?: string;
  private actionLogList: EngagementActionProps[] = [];

  public phases = enumToOptions(Phase);
  private postMessage: string = "";
  private formId: string = "";
  private linkClicked: boolean = false;

  constructor() {
    super();

    this.authService = AuthService.getInstance();
    this.engagementsService = EngagementsService.getInstance();
    this.fwkService = FrameworkService.getInstance();
    this.s3Service = S3Service.getInstance();

    this.engagement = null;
  }

  get currentProcess() {
    return "done";
  }

  get engagementInteractions() {
    return this.actionLogList.filter(
      (a) => a.action === EngagementAction.LogInteraction
    );
  }

  get engagementMessages() {
    if (!this.actionLogList) return [];
    return this.actionLogList
      .filter((action) => action.action === EngagementAction.PostMessage)
      .map((action) => {
        const messageData = action.actionData as Message;
        return messageData;
      });
  }

  get executionWeekNo() {
    const nowWeek = moment().week() % 52;
    const startWeek = moment(this.engagement!.creationDate!).week() % 52;

    return nowWeek - startWeek;
  }

  async created() {
    this.engagementId = this.$route.params.engagement!;
    await this.fetchData();
  }

  async archive(value = true) {
    this.engagement!.archived = value;
    await this.engagement!.$save(EngagementAction.Archive);
    this.fwkService.addAlert(
      "success",
      `Successfully ${value ? "archived" : "unarchived"} engagement`
    );
    await this.fetchData();
  }

  async fetchData() {
    const engagement = await this.engagementsService.getById(
      this.engagementId!
    );
    this.engagement = engagement;

    const actions = await this.engagementsService.getEngagementActions(
      this.engagementId!
    );
    this.actionLogList = actions;
  }

  async linkClick() {
    const engagement = await this.engagementsService.getEngagementByOnBoardingRequest(this.formId)
    if (engagement.id) return void this.fwkService.addAlert("error", 'This Request Id is already linked with another Engagement');
    this.linkClicked = true;
  }

  unClickLink() {
    this.linkClicked = false;
  }

  async saveForm(overwrite: boolean = false) {
    this.unClickLink();

    const form: any = await ProspectsService.getInstance().getProspectById(
      this.formId
    );
    if (form.errorMessage) {
      return this.fwkService.addAlert("error", "No Such Request Form Exists");
    }

    if (this.engagement) {
      this.engagement.onBoardingRequestId = this.formId;
      overwrite && populateEngagementWithRequest(this.engagement, form);
      this.saveEngagement();
    }
  }

  async saveEngagement() {
    try {
      await this.engagement!.$save();
      await this.fwkService.addAlert("success", "Successfully saved engagement");
      await this.fetchData();
    } catch (error) {
      await this.fwkService.addAlert("error", "Failed to save engagement");
    }
  }

  async confirm(message: string) {
    await this.fwkService.confirm(message);
  }

  async addAlert(type: string, message: string) {
    await this.fwkService.addAlert(type, message);
  }

  async savePostMessage() {
    const Message = this.postMessage;
    const Source = this.authService.user;
    const d = new Date();

    await this.engagementsService.logAction(
      this.engagement!.id!,
      EngagementAction.PostMessage,
      {
        Message,
        Source,
        Date: d,
      }
    );

    await this.fetchData();
    this.postMessage = "";
  }

  async uploadFile() {
    const ref = this.$refs.newfile as any;
    const file = ref.files[0];
    await this.engagementsService.addFileToEngagement(this.engagement!, file);

    ref.value = "";
  }

  async updateAWSServices(services: AWSService[]) {
    this.engagement!.awsServices = services;
  }
}
