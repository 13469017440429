
import { Vue, Component, Prop } from 'vue-property-decorator'
import Engagement from '../models/Engagement'
import EngagementsService from '../services/EngagementsService';
import { EngagementAction, EngagementActionProps } from '../models/EngagementActions';
import { EngagementInteractionTaskProps, EngagementInteraction, EngagementInteractionTask } from '../models/EngagementInteraction';
import AuthService from '../services/AuthService';
import ConfigurationService from '../services/ConfigurationService';
import FrameworkService from '../services/FrameworkService';
import Markdown from 'markdown-it';

export class ExtendedInteractionTask extends EngagementInteractionTask {
  public readonly $interaction: EngagementActionProps<EngagementInteraction>;

  constructor (interaction: EngagementActionProps<EngagementInteraction>, task: EngagementInteractionTaskProps) {
    super(task);
    this.$interaction = interaction;
  }

  getProps () {
    return {
      ...super.getProps(),
      $interaction: this.$interaction
    }
  }
}

@Component
export default class EngagementFollowUpList extends Vue {
  @Prop(Engagement) readonly engagement!: Engagement;

  private readonly authService: AuthService = AuthService.getInstance();
  private readonly configService: ConfigurationService = ConfigurationService.getInstance();
  private readonly engagementsService: EngagementsService = EngagementsService.getInstance();

  private readonly md = new Markdown();
  private readonly actionsTableName = this.configService.get("ADMIN_ENGAGEMENT_ACTIONS_TABLE");

  private engagementActions: EngagementActionProps[] = [];

  get interactions (): EngagementActionProps<EngagementInteraction>[] {
    return this.engagementActions
      .filter(action => action.action === EngagementAction.LogInteraction) as any[];
  }

  get todos () {
    const items = this.interactions
      .filter((interaction: EngagementActionProps<EngagementInteraction>) => interaction.actionData!.FollowUps)
      .map((interaction: EngagementActionProps<EngagementInteraction>): ExtendedInteractionTask[] => interaction.actionData!.FollowUps!.map(f => new ExtendedInteractionTask(interaction, f)))
      // @ts-ignore
      .flat()
      .filter(i => i)
      .map((todo: ExtendedInteractionTask): any => ({
        ...todo.getProps(),
        Text: this.md.render(todo.Text)
      }));
    return items;
  }

  get todosAssignedToMe () {
    return this.todos
      .filter(todo => todo.Assignee === `${this.authService.user!}@`)
  }

  get todosOwnedByMe () {
    return this.todos
      .filter(todo => todo.Assignee !== `${this.authService.user!}@`)
      .filter(todo => todo.Owner === this.authService.user!)
  }

  async created () {
    await this.fetchData();
  }

  async mounted () {}

  async fetchData () {
    this.engagementActions = await this.engagementsService.getEngagementActions(this.engagement.id!);
  }

  async checkTodo (todo: ExtendedInteractionTask) {
    // Set fulfillment status in text
    const todoClazz = new EngagementInteractionTask(todo);
    const newText = todoClazz.changeFulfillmentInText(todo.$interaction.actionData!.details!);
    todo.$interaction.actionData!.details = newText;
    await this.saveTodo(todo);
  }

  async saveTodo (todo: ExtendedInteractionTask) {
    const action = todo.$interaction!;
    const todoIndex = action.actionData!.FollowUps!.map(f => f.TaskId).indexOf(todo.TaskId);
    action.actionData!.FollowUps![todoIndex].Fulfilled = todo.Fulfilled;
    FrameworkService.getInstance().addAlert('success', 'Task status changed');
  }
}
