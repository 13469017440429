// @ts-ignore
import { AwsUi } from '@amzn/awsui-components';
import { VueConstructor as Vue } from 'vue';

function recursiveComponentLookup (element: any, node: any) {
  let current = node.parentNode;
  while (current != null) {
    if (current === element) return true;
    current = current.parentNode;
  }
  return false;
}

function findDefaultRegion (definition: any) {
  for (let regionName in definition.regions) {
    if (definition.regions[regionName].isDefault) {
      return regionName;
    }
  }
}

// todo how to integrate tabs tabs property with vue ???
// todo integrate with v-model tabs activeTab
function defineComponent (Vue: Vue, A: any, componentName: string, verbose: Function, superVerbose: Function) {
  const definition = AwsUi.__componentDefinitions[componentName];
  const defaultRegionName = findDefaultRegion(definition);
  const regions = definition.regions || {};
  const events = definition.events || {};
  const properties = definition.properties || {};
  const vueComponentName = componentName.replace('awsui-', 'awsui-v-');

  // hide providing of component name for verbose
  const compVerbose = (msg: any) => verbose(vueComponentName, msg);
  const compSuperVerbose = (msg: any) => superVerbose(vueComponentName, msg);

  compVerbose(`registering polaris component ${componentName}`);
  compVerbose(`events ${Object.keys(events)}`);
  compSuperVerbose(`events ...`);
  compSuperVerbose(events);
  compVerbose(`props ${Object.keys(properties)}`);
  compSuperVerbose(`props ...`);
  compSuperVerbose(properties);

  const computed = void 0;
  // if (componentName === 'awsui-tabs') {
  //     computed = {
  //         comp: {
  //             get() {
  //                 return this.value;
  //             },
  //             set(value) {
  //                 verbose('set value');
  //                 verbose(value);
  //                 this.$emit('input', value);
  //             }
  //         }
  //     }
  // }

  const ngModelSupport = definition.wrapperSupport && definition.wrapperSupport.ngModel;
  const props = Object.keys(definition.properties)
  if (ngModelSupport) {
    const index = props.indexOf(ngModelSupport.value);
    props[index] = 'value'
  }

  return Vue.component(vueComponentName, {
    // template: `<${componentName}/>`,
    render (h) {
      return h(componentName);
    },

    computed: computed,

    updated () {
      compVerbose('[updated] started');
      const item = this.$el as any;
      const awsUiComponent = item.component;

      const focusedElement = document.activeElement;
      let skipUpdate = false;
      if (focusedElement) {
        const isInput = ['input', 'textarea'].indexOf(focusedElement.tagName.toLowerCase()) !== -1;
        const isTargetComponent = recursiveComponentLookup(item, focusedElement);
        skipUpdate = isInput && isTargetComponent;
      } else {
        skipUpdate = false
      }

      if (skipUpdate) {
        compVerbose('[updated] skipped to preserve input experience');
      } else {
        awsUiComponent.__batchUpdate(() => {
          for (const prop in regions) {
            this.updateProp(prop, this[prop]);
          }
        });
        compVerbose('[updated] finished');
      }
    },

    props,

    methods: {
      updateProp (prop: any, value: any) {
        const awsUiComponent = (this.$el as any).component;

        if (prop in regions) {
          const slotName = prop === defaultRegionName ? 'default' : prop;
          const effectiveValue = value || this.$slots[slotName];
          compVerbose(`update region ${slotName} = ${effectiveValue}`);
          compSuperVerbose(`update region ${slotName} = ...`);
          compSuperVerbose(effectiveValue);
          if (effectiveValue) {
            if (AwsUi.__isPrimitiveRegion(effectiveValue)) {
              awsUiComponent.setRegion(prop, value);
            } else {
              const c = new A({ parent: this, propsData: { node: effectiveValue[0] } });
              c.$mount();
              awsUiComponent.setRegion(prop, c.$el);
            }
          } else awsUiComponent.removeRegion(prop, null);
        } else {
          compVerbose(`update prop ${prop} = ${value}`);
          compSuperVerbose(`update prop ${prop} = ...`);
          compSuperVerbose(value);

          // if (vueComponentName === 'awsui-v-attribute-editor' && prop === 'definition' && value) {
          //     compVerbose(`special render case awsui-v-attribute-editor.definition`);
          //     value.forEach((it, index) => {
          //         // _this.renderToMithril("attribute-label-" + itemIndex + "-" + controlIndex, label, [
          //         //     { $isVisible: isVisible }
          //         // ])
          //         // const args = [{ $item: it }, { $itemIndex: 1 }];
          //         it.control = item => {
          //             // return awsUiComponent.renderToMithril(`item-${index}`, '<b>11</b>', args)
          //             // https://vuejs.org/v2/guide/render-function.html#createElement-Arguments
          //             return this.$createElement('span', {}, [this._v('some text')])
          //         };
          //     });
          // }

          if (value) awsUiComponent[prop] = value;
          else awsUiComponent.__setToDefault(prop);
        }
      }
    },

    mounted () {
      compVerbose('[mounted] start');
      AwsUi.activate(this.$el, {
        // @ts-ignore
        __getWrapperRenderHooks: ({ lifecycleKey, renderable, data }) => {
          compSuperVerbose(`__getWrapperRenderHooks (${lifecycleKey}, ..., ...)`);
          compSuperVerbose(renderable);
          compSuperVerbose(data);

          // Transform the data from [{a:'b'}, {c:'d'}] to ['b', 'd']
          const inputData = data.map((entry: any) => entry[Object.keys(entry)[0]]);
          const isFunction = typeof renderable === 'function';
          const rendered = isFunction ? renderable(...inputData) : renderable;

          return {
            componentDidMount: (element: any) => {
              compVerbose('__getWrapperRenderHooks.componentDidMount ...');
              compVerbose(element);
              if (rendered && rendered._isVue) rendered.$mount(element);
              // this case just to simplify client code and allow pass just render function instead of whole vue component definition
              // @ts-ignore
              else if (rendered && typeof rendered === 'function') new Vue({ functional: true, render: rendered }).$mount(element);
              else element.innerHTML = rendered;
              return null;
            },
            componentDidUpdate: (element: any) => {
              compVerbose('__getWrapperRenderHooks.componentDidUpdate ...');
              compVerbose(element);
              element.innerHTML = rendered;
              return null;
            },
            componentWillUnmount: (element: any) => {
              compVerbose('__getWrapperRenderHooks.componentWillUnmount ...');
              compVerbose(element);
            }
          }
        }
      });
      const awsUiComponent = (this.$el as any).component;

      if (ngModelSupport) {
        const propertyName = ngModelSupport.value;

        // Assign property value first time
        (this as any)[propertyName] = this.value;

        // Listen to event on the model, write back to the model on any change
        this.$el.addEventListener('awsui:' + ngModelSupport.event, (event) => {
          if (event.target !== this.$el) {
            return;
          }

          this.$emit('input', (event as any).detail[propertyName]);
        });
      }

      // use batch to minimize amount of re-rendering
      awsUiComponent.__batchUpdate(() => {
        // go cross all possible component properties and set value or default

        for (const prop in properties) {
          // Ignore private properties
          if (prop.match(/^__/)) continue;
          const value = this[prop];
          this.updateProp(prop, value);
        }
      });

      // todo add support of listener update, or test
      // todo avoid setting all listeners, check that parent component is listening
      for (const eventType in events) {
        if (eventType === 'input') continue;
        awsUiComponent.node.addEventListener(`awsui:${eventType}`, (event: any) => {
          compVerbose(`emit event ${eventType}`);
          this.$emit(eventType, event);
        });
        // old approach doesn't support syntetic awsui event types, only native
        // this.$el.addEventListener(eventType, event => {
        //     verbose(vueComponentName, `emit event ${eventType}`);
        //     this.$emit(eventType, event);
        // });
      }

      // define watch to get updates for properties, and forward them to aws-ui
      for (const prop in properties) {
        this.$watch(prop, function (value) {
          this.updateProp(prop, value);
        });
      }

      (this.$el as any).component.hasBeenAttached();

      // todo check if we can watch for slots instead of reupdate all slots during updated()
      // for (const region in regions) {
      //     this.$watch(`$slots.default`, function (value) {
      //         console.log(`region updated ${region}`);
      //     });
      // }
    },

    beforeDestroy () {
      (this.$el as any).component.hasBeenDetached();
    }
  });
}

class Plugin {
  // noinspection JSUnusedGlobalSymbols
  static install (Vue: Vue, options = { verbose: false, superVerbose: false, filter: void 0 }) {
    const A = Vue.extend({
      props: ['node'],
      render () {
        return this.node;
      }
    });

    Vue.config.ignoredElements = Vue.config.ignoredElements.concat(
      Object.keys(AwsUi.__componentDefinitions)
    );

    const verbose = options.verbose ? (c: any, m: any) => {
      if (!options.filter || c.indexOf(options.filter) > -1) console.log(m);
    } : () => void 0;

    const superVerbose = options.superVerbose ? (c: any, m: any) => {
      if (!options.filter || c.indexOf(options.filter) > -1) console.log(m);
    } : () => void 0;

    Object.keys(AwsUi.__getPublicComponentDefinitions()).map(def => {
      return defineComponent(Vue, A, def, verbose, superVerbose);
    });
  }
}

export default Plugin;

// Vue.component("awsui-vue-input", {
//     template: "<awsui-input />",
//     props: ["value"],
//     mounted() {
//         AwsUi.activate(this.$el);
//         const component = this.$el.component;
//         component.value = this.value;
//
//         this.$el.addEventListener("awsui:input", event => {
//             this.$emit("input", event.detail.value);
//         });
//     },
//
//     beforeDestroy() {
//         this.$el.component.hasBeenDetached();
//     }
// });

// Vue.component("awsui-vue-alert", {
//     template: "<awsui-alert />",
//     props: ["type", "header", "content"],
//     mounted() {
//         AwsUi.activate(this.$el);
//         const component = this.$el.component;
//         component.__batchUpdate(() => {
//             component.type = this.type;
//             component.header = this.type;
//             component.content = this.type;
//         });
//     },
//
//     beforeDestroy() {
//         this.$el.component.hasBeenDetached();
//     }
// });

// Vue.component("awsui-vue-form-field", {
//     template: `<awsui-form-field>
//     <span ref="control">
//       <slot name="control" />
//     </span>
//     <span ref="secondaryControl">
//       <slot name="secondaryControl"/>
//     </span>
//   </awsui-form-field>`,
//     props: ["label"],
//     mounted() {
//         AwsUi.activate(this.$el);
//         const component = this.$el.component;
//         component.__batchUpdate(() => {
//             component.label = this.label;
//             component.setRegion("control", this.$refs.control);
//             component.setRegion("secondaryControl", this.$refs.secondaryControl);
//         });
//     },
//
//     beforeDestroy() {
//         this.$el.component.hasBeenDetached();
//     }
// });

// Vue.component("awsui-vue-table", {
//     template: "<awsui-table />",
//     props: ["items", "columnDefinitions"],
//     mounted() {
//         AwsUi.activate(this.$el, {
//             // TODO: implement
//             __getWrapperRenderHooks({lifecycleKey, renderable, data}) {
//                 return {
//                     componentDidMount: () => {
//                         // initial render
//                     },
//                     componentDidUpdate: () => {
//                         //update when re-renders
//                     },
//                     componentWillUnmount: () => {
//                         // clean up
//                     }
//                 };
//             }
//         });
//         const component = this.$el.component;
//
//         component.items = this.items;
//     },
//
//     beforeDestroy() {
//         this.$el.component.hasBeenDetached();
//     }
// });

/** TODO:
  * Use React wrapper as a reference: https://code.amazon.com/packages/AWS-UI-Wrapper-React/blobs/mainline/--/src/createClass.ts
  * 1. Use generic method to define components in the loop
  * 4. Optimize event listeners
  * 5. Slot rendering is unreliable, try using portals: https://github.com/LinusBorg/portal-vue
  */
