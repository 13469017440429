import Vue from 'vue';
import App from './App.vue';
import Router from "vue-router";
import setUpRouter from './setUpRouter';
import Banner from '@/components/Banner.vue';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@amzn/awsui-components/index.min.css'
import 'v-markdown-editor/dist/v-markdown-editor.css';
import VueShowdown from 'vue-showdown'

import Polaris from '@/polaris';
import Amplify, * as AmplifyModules from 'aws-amplify'
// @ts-ignore
import { AmplifyPlugin } from 'aws-amplify-vue'
// @ts-ignore
import Editor from 'v-markdown-editor'
import { fetchConfig } from "@/assets/config";
import ConfigurationService from "@/services/ConfigurationService";
import setupApp from './helpers/setupApp';
// @ts-ignore
import { VuePlugin } from 'vuera';

Vue.use(VuePlugin);
Vue.use(Polaris);
Vue.use(Editor);
Vue.use(VueShowdown, {
  flavor: 'github',
})
Vue.use(Element);

export const reactGlobal: any = {};

fetchConfig()
  .then(({ config }) => {
    ConfigurationService.getInstance(config);
    return setupApp(config);
  })
  .then(() => {
    Vue.use(Router);
    Vue.use(AmplifyPlugin, AmplifyModules)
    Vue.config.productionTip = false
    reactGlobal.router = setUpRouter();
    new Vue({
      components: {
        Banner
      },
      router: reactGlobal.router,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch((error) => { console.log(error) })
