import Router from "vue-router";
import EngagementDetails from "@/views/EngagementDetails.vue";
import EngagementManagement from "@/views/EngagementManagement.vue";
import FileSearch from "@/views/FileSearch.vue";
import PublicLanding from "./views/PublicLanding.vue";
import PrivateLanding from "./views/PrivateLanding.vue";
import EngagementCreation from "@/views/EngagementCreation.vue";
import Settings from "@/views/Settings.vue";
import EngagementInteractionLogger from "./views/EngagementInteractionLogger.vue";
import CustomerOnboarding from "./views/CustomerOnboarding.vue";
import CustomerOnboardingSuccessView from "./views/CustomerOnboardingSuccessView.vue";
import CustomerOnboardingListView from "./views/CustomerOnboardingList.vue";
import CustomerOnboardingDetailsView from "./views/CustomerOnboardingDetailsView.vue";
import NotFound from "./views/NotFound.vue";
import NotAllowed from "./views/NotAllowed.vue";
import AuthService from "@/services/AuthService";
import EngagementsService from "@/services/EngagementsService";

const setUpRouter = () => {
  const auth = AuthService.getInstance();

  const router = new Router({
    mode: "history",
    routes: [
      {
        path: "/",
        name: "Landing",
        component: auth.team ? PrivateLanding : PublicLanding,
        meta: {
          interactiveHelp: "activity-tracking",
          fullSize: true,
          public: true
        }
      },
      {
        path: "/engagements",
        name: "Engagement Management",
        component: EngagementManagement,
        meta: {}
      },
      {
        path: "/files",
        name: "Files",
        component: FileSearch,
        meta: {}
      },
      {
        path: "/engagements/new",
        name: "Engagement Creation",
        component: EngagementCreation,
        meta: {
          breadcrumbs: [
            {
              href: "/engagements",
              label: "Engagements"
            }
          ]
        }
      },
      {
        path: "/engagements/:engagement",
        name: "Engagement Details",
        component: EngagementDetails,
        meta: {
          exception: async ({ engagement: engagementId }: any) => {
            const { accountOwners, accountSAs } = await EngagementsService.getInstance().getById(engagementId);
            return accountOwners.includes(auth.user!) || accountSAs.includes(auth.user!);
          },
          breadcrumbTitle: (route: any) => route.params.engagement,
          breadcrumbs: [
            {
              href: "/engagements",
              label: "Engagements"
            }
          ]
        }
      },
      {
        path: "/engagements/:engagement/log",
        name: "Engagement Interaction Logger",
        component: EngagementInteractionLogger,
        meta: {
          breadcrumbTitle: (route: any) => "Interaction logger",
          breadcrumbs: [
            {
              href: "/engagements",
              label: "Engagements"
            },
            {
              href: (route: any) => `/engagements/${route.params.engagement}`,
              label: (route: any) => route.params.engagement
            }
          ]
        }
      },
      {
        path: "/engagements/:engagement/log/:action",
        name: "Interaction Details",
        component: EngagementInteractionLogger,
        meta: {
          exception: async ({ engagement: engagementId }: any) => {
            const { accountOwners, accountSAs } = await EngagementsService.getInstance().getById(engagementId);
            return accountOwners.includes(auth.user!) || accountSAs.includes(auth.user!);
          },
          breadcrumbTitle: (route: any) => `Interaction ${route.params.action}`,
          breadcrumbs: [
            {
              href: "/engagements",
              label: "Engagements"
            },
            {
              href: (route: any) => `/engagements/${route.params.engagement}`,
              label: (route: any) => route.params.engagement
            }
          ]
        }
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
          breadcrumbs: [
            {
              href: "/settings",
              label: "settings"
            }
          ]
        }
      },
      {
        path: "/customer-onboarding",
        name: "CustomerOnboarding",
        component: CustomerOnboarding,
        meta: {
          public: true
        }
      },
      {
        path: "/customer-onboarding/list",
        name: "CustomerOnboardingList",
        component: CustomerOnboardingListView,
        meta: {
          public: true
        }
      },
      {
        path: "/customer-onboarding/:requestId",
        name: "CustomerOnboardingDetails",
        component: CustomerOnboardingDetailsView,
        meta: {
          public: true
        }
      },
      {
        path: "/customer-onboarding/:requestId/success",
        name: "CustomerOnboardingSuccess",
        component: CustomerOnboardingSuccessView,
        meta: {
          public: true
        }
      },
      {
        path: "/notallowed",
        name: "NotAllowed",
        component: NotAllowed,
        meta: {
          public: true
        }
      },
      {
        path: "*",
        name: "NotFound",
        component: NotFound,
        meta: {
          public: true
        }
      }
    ]
  });

  router.beforeEach(async (to, from, next) => {
    if (auth.team || to?.meta?.public) {
      next();
    } else {
      if (to.meta?.exception && await to.meta?.exception(to.params)) {
        next();
      } else {
        next("/notallowed");
      }
    }
  });

  return router;
};

export default setUpRouter;
