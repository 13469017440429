export enum SelectorSourceContainer {
  sidebar = 'sidebar',
  content = 'content',
  global = 'global'
}

export enum HelpStepDataLocation {
  top = 'top',
  left = 'left',
  right = 'right',
  bottom = 'bottom',
  center = 'center'
}

export enum HelpStepDataAlignment {
  left = 'left',
  center = 'center',
  right = 'right'
}

export interface InteractiveHelpSection {
  id: string;
  steps: InteractiveHelpStep[];
}

export interface InteractiveHelpStep {
  title: string;
  description: string;
  selector: string;
  selectorSource: SelectorSourceContainer;
  dataAlignment: HelpStepDataAlignment;
  dataLocation?: HelpStepDataLocation;
  style?: { [key: string]: any };
  color?: string;
  autoContinue?: InteractiveHelpStepAutoContinue;
  inheritParentPosition?: boolean
}

export interface InteractiveHelpStepAutoContinue {
  message: string;
  handler: Function;
}
