
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import UserInfo from '@/components/UserInfo.vue';
import AuthService from '@/services/AuthService';
import { NoCache } from '../decorators';
import { v4 as uuid } from 'uuid';

@Component({
  components: {
  UserInfo
  }
  })
export default class UserSelectorComponent extends Vue {
  @Prop(String) readonly title!: string;
  @Prop({ default: () => [] }) readonly value!: string[];
  @Prop(Boolean) readonly readonly!: boolean;
  @Prop({ default: () => false }) readonly horizontal!: boolean;

  private readonly authService: AuthService = AuthService.getInstance();
  private currentValue: string | null = null;
  public depKey: string = '1'

  @NoCache
  get userValue () {
    return this.value;
  }

  set userValue (value: string[]) {
    this.$emit('input', value);
    this.depKey = uuid();
  }

  get manualValue () {
    return this.currentValue
  }

  set manualValue (value: string | null) {
    const end = value ? value.indexOf('@') : null;
    if (!end || end === -1) {
      this.currentValue = value;
    } else {
      const user = value!.substring(0, end);
      const existing = this.value;
      const exists = existing.indexOf(user) !== -1;
      if (!exists) {
        this.userValue = this.userValue.concat([user]);
        this.manualValue = null;
      }
    }
  }

  get placeholderInitials () {
    return (this.title).split(' ').map(i => i.charAt(0)).join('').toUpperCase();
  }

  itsMe () {
    const existing = this.userValue;
    const user = this.authService.user!;
    if (existing.indexOf(user) === -1) {
      const newItems = existing.concat([user]);
      this.userValue = newItems;
    }
  }

  removeUser (index: number) {
    // @ts-ignore
    const newList = [].concat(this.value);
    newList.splice(index, 1);
    this.userValue = newList;
  }
}
