import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Pagination,
  Input,
  FormField,
  Box,
  SpaceBetween,
  TokenGroup,
  Spinner
} from '@amzn/awsui-components-react';
import { getFilenameFromKey, renderFilePreview } from "./EngagementFiles";
import Engagement from '@/models/Engagement';
import { EngagementFileMeta } from '@devax/models/engagements/Engagement';
import './EngagementFileModal.scss';
import EngagementsService from '@/services/EngagementsService';
import S3Service from '@/services/S3Service';

interface Props {
  openAtIndex?: number;
  files: any[];
  open: boolean;
  onClose: () => void;
  engagement: Engagement;
  engagementsService: EngagementsService;
  addAlert: (type: string, message: string) => void;
  s3Service: S3Service;
}

export default function EngagementFileModal(props: Props) {
  const [fileMeta, setFileMeta] = useState<EngagementFileMeta[]>([]);
  const [fetching, setFetching] = useState(true);
  const [tagInput, setTagInput] = useState("");
  const [currentIndex, setCurrentIndex] = useState(props.openAtIndex || 0);

  useEffect(() => {
    setCurrentIndex(props.openAtIndex || 0);
  }, [props.openAtIndex]);

  const fetchData = async () => {
    setFetching(true);
    const fetchedFileMeta = await props.engagementsService.getEngagementFileMeta(props.engagement.id);
    const ordering: { [key: string]: number } = {};
    props.files.forEach((file, index) => {
      ordering[file.key] = index;
    });
    const ordered = [...fetchedFileMeta];
    fetchedFileMeta.forEach(fm => {
      const index = ordering[fm.fileKey];
      ordered[index] = fm;
    })
    setFileMeta(ordered);
    setFetching(false);
  }

  useEffect(() => {
    if (!props.open) return;
    fetchData();
  }, [props.open, props.engagement]);

  const handleClose = () => {
    props.onClose();
    setFetching(true);
  }

  const getFile = async (file: any) => {
    const url = await props.s3Service.getSignedUrl(file.key);
    window.open(url, "_blank", "noopener,noreferrer");
  }

  if (!props.files.length || !props.open) return null;

  return (
    <div id='engagement-file-modal'>
      <Modal
        size='large'
        onDismiss={handleClose}
        visible={props.open}
        header="Edit file information"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              {/* @ts-ignore */}
              <Button
                variant="link"
                onClick={handleClose}
              >
                Cancel
              </Button>
              {/* @ts-ignore */}
              <Button
                variant="primary"
                onClick={async () => {
                  handleClose();
                  props.engagementsService.updateEngagementFileMeta(
                    props.engagement.id,
                    fileMeta
                  ).then(async _data => {
                    props.addAlert('success', 'Successfully updated files');
                  })
                    .catch(error => {
                      props.addAlert('error', 'Error updating files');
                      throw error;
                    });
                }}
                disabled={fetching}
              >
                Save
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <div id='engagement-file-modal-contents'>
          {fetching || !fileMeta.length
            ? <Spinner />
            : (
              <div className='flex-container'>
                <div className='left'>
                  <SpaceBetween direction='vertical' size='m'>
                    <FormField
                      label="Customer"
                    >
                      {/* @ts-ignore */}
                      <Input
                        disabled
                        value={fileMeta[currentIndex].customer}
                        onChange={_e => {}}
                      />
                    </FormField>
                    <FormField
                      label="Filename"
                    >
                      {/* @ts-ignore */}
                      <Input
                        disabled
                        value={getFilenameFromKey(fileMeta[currentIndex].fileKey) || ""}
                        onChange={_e => {}}
                      />
                    </FormField>
                    <FormField
                      label="Owner"
                    >
                      {/* @ts-ignore */}
                      <Input
                        disabled
                        value={fileMeta[currentIndex].owner}
                        onChange={_e => {}}
                      />
                    </FormField>
                    <FormField
                      label="Description"
                    >
                      {/* @ts-ignore */}
                      <Input
                        value={fileMeta[currentIndex].description}
                        onChange={e => {
                          const newFileMeta = [...fileMeta];
                          newFileMeta[currentIndex].description = e.detail.value;
                          setFileMeta(newFileMeta);
                        }}
                      />
                    </FormField>
                    <FormField
                      label="Tags"
                      errorText={tagInput && fileMeta[currentIndex].tags.length >= 3
                        ? "Maximum 3 tags allowed."
                        : fileMeta[currentIndex].tags.includes(tagInput)
                          ? `Tag '${tagInput} has already been added.`
                          : undefined
                      }
                    >
                      <div className='tag-input-container'>
                        <div className='tag-input-text'>
                          {/* @ts-ignore */}
                          <Input
                            value={tagInput}
                            onChange={e => setTagInput(e.detail.value)}
                            onKeyDown={e => {
                              if (
                                e.detail.keyCode !== 13 ||
                                  !tagInput ||
                                  fileMeta[currentIndex].tags.length >= 3 ||
                                  fileMeta[currentIndex].tags.includes(tagInput)
                              ) return;
                              const newFileMeta = [...fileMeta];
                              newFileMeta[currentIndex].tags.push(tagInput.trim());
                              setFileMeta(newFileMeta);
                              setTagInput("");
                            }}
                          />
                        </div>
                        <div className='tag-input-add'>
                          {/* @ts-ignore */}
                          <Button
                            iconName='add-plus'
                            onClick={() => {
                              if (
                                !tagInput ||
                                  fileMeta[currentIndex].tags.length >= 3 ||
                                  fileMeta[currentIndex].tags.includes(tagInput)
                              ) return;
                              const newFileMeta = [...fileMeta];
                              newFileMeta[currentIndex].tags.push(tagInput.trim());
                              setFileMeta(newFileMeta);
                              setTagInput("");
                            }}
                          />
                        </div>
                      </div>
                      <TokenGroup
                        onDismiss={({ detail: { itemIndex } }) => {
                          const newFileMeta = [...fileMeta];
                          newFileMeta[currentIndex].tags.splice(itemIndex, 1);
                          setFileMeta(newFileMeta);
                        }}
                        items={fileMeta[currentIndex].tags.map(tag => ({
                          label: tag,
                          dismissLabel: `Remove ${tag}`
                        }))}
                      />
                    </FormField>
                  </SpaceBetween>
                </div>
                <div className='spacer' />
                <div className='right'>
                  <SpaceBetween direction='vertical' size='m'>
                    <div className='pagination-container'>
                      <div className='pagination'>
                        <Pagination
                          currentPageIndex={currentIndex + 1}
                          onChange={({ detail }) =>
                            setCurrentIndex(detail.currentPageIndex - 1)
                          }
                          pagesCount={props.files.length}
                        />
                      </div>
                    </div>
                    <div
                      className='preview'
                      onClick={() => {
                        getFile(props.files[currentIndex]);
                      }}
                    >
                      {renderFilePreview(props.files[currentIndex])}
                    </div>
                  </SpaceBetween>
                </div>
              </div>
            )
          }
        </div>
      </Modal>
    </div>
  );
}
