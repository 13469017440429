
import { Vue, Component } from "vue-property-decorator";
import EngagementBasicDetails from "../components/EngagementBasicDetails.vue";
import { v4 as uuid } from "uuid";
import AuthService from "@/services/AuthService";
import EngagementAccountTeamSelection from "../components/EngagementAccountTeamSelection.vue";
import CustomerOnboardingSteps from "../components/CustomerOnboardingSteps.vue";
import DevaxForm from "../components/forms/DevAxForm.vue";
import FrameworkService from "@/services/FrameworkService";
import {
  CustomerOnboardingRequest,
  CustomerOnboardingForm,
  HeardFrom,
  Segment,
  BusinessSize,
  Stage,
  Vertical,
  DevOrganisation,
  CustomerMotivation,
  DevOrgSize,
  QDates,
  EnglishBoolean,
  AgileTypes
} from "@devax/models/customer-onboarding";
import { TShirtSize } from "@devax/models/base/TShirtSizes";
import moment from "moment";
import {
  FormData,
  FormDataType,
  FormFieldType
} from "@devax/models/base/forms/Form";
import ProspectsService from "../services/ProspectsService";
import TeamsService from "../services/TeamsService";

export interface OnboardingData {
  categories: OnboardingDataCategory[];
}

export interface OnboardingDataCategory {
  form: FormData;
  horizontal?: boolean;
}

@Component({
  components: {
  DevaxForm
  }
  })
export default class CustomerOnboarding extends Vue {
  private readonly authService: AuthService = AuthService.getInstance();
  private readonly fwkService: FrameworkService;

  public currentStep = 0;

  constructor() {
    super();
    this.fwkService = FrameworkService.getInstance();
  }

  public readonly onboardingRequest: CustomerOnboardingRequest = {
    AccountId: "",
    AccountOwners: [],
    AdoptionDetails: "",
    BusinessSegment: Segment.DONT_KNOW,
    BusinessSize: BusinessSize.DONT_KNOW,
    BusinessVertical: Vertical.Unknown,
    Competitors: [],
    CreatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
    CustomerChallenge: "",
    CustomerDevOrganisation: DevOrganisation.Unknown,
    CustomerMotivation: CustomerMotivation.Unknown,
    CustomerName: "",
    CustomerOverview: "",
    CustomerStage: Stage["No meaningful Usage or Billing of AWS"],
    DeveloperCommitment: "unknown",
    DevOrgSize: "" as DevOrgSize,
    EngagementOverview: "",
    EstimatedStartDate: QDates.Unknown,
    ExecutiveSponsors: "",
    Id: uuid(),
    IsCompetitiveUseCase: EnglishBoolean.No,
    ITStrategy: "",
    OpportunityLink: "",
    ProductionMotivation: "",
    SolutionsArchitects: [],
    StatementOfWork: [],
    CustomerUsesAgile: AgileTypes.Unknown,
    AgileCadence: null,
    TeamId: this.$route.query.team as string,
    TechnicalAccountManagers: [],
    UpdatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
    UserId: this.authService.user!,
    HeardFrom: '' as HeardFrom
  };

  async created() {
    const teams = await TeamsService.getInstance().listTeams();
    const teamIds = teams.filter(team => team.acceptRequests).map(team => team.id);
    if (!teamIds.includes(this.$route.query.team as string)) this.$router.push("/");
  }

  get formValid() {
    const currentCategories = this.onboardingData[this.currentStep].categories;
    const requiredFields = currentCategories
      // @ts-ignore
      .map(cat => Object.values(cat.form.Properties!))
      .flat()
      .filter((prop: any) => prop.Required)
      .map((prop: any) => prop.Name);

    const invalidData = requiredFields.filter(
      (field: any) =>
        !(this.onboardingRequest as any)[field].length ||
        (!(this.onboardingRequest as any)[field] &&
          (this.onboardingRequest as any)[field] !== false &&
          (this.onboardingRequest as any)[field] !== 0)
    );

    return !invalidData.length;
  }

  get onboardingData(): OnboardingData[] {
    return CustomerOnboardingForm;
  }

  async submitRequest() {
    this.currentStep = this.onboardingData.length + 1;
    try {
      const prospect = await ProspectsService.getInstance().saveProspect(
        this.onboardingRequest as any
      );
      this.$router.push(`/customer-onboarding/${prospect.Id}/success`);
    } catch (error) {
      this.fwkService.addAlert("error", "Failed to create prospect");
    }
  }
}
