
import moment from 'moment';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { EngagementActionProps } from '../models/EngagementActions'
import { EngagementInteraction } from '../models/EngagementInteraction';
import { TeamMember } from '../models/Team';
import ConfigurationService from '../services/ConfigurationService';

@Component
export default class EngagementInteractionList extends Vue {
  private readonly configService: ConfigurationService = ConfigurationService.getInstance();

  @Prop(String) readonly engagementId!: string;
  @Prop(Array) readonly interactions!: EngagementActionProps<EngagementInteraction>[];

  public userList: TeamMember[] = [];
  public interactionList?: EngagementActionProps<EngagementInteraction>[];

  public engagementInteractionListColums = [
    {
      id: 'subject',
      header: 'Subject',
      cell: (item: EngagementActionProps<EngagementInteraction>) => `<a href="/engagements/${item.engagementId}/log/${item.id}">${item.actionData ? item.actionData.title : 'unknown'}</a>`
    },
    {
      id: 'date',
      header: 'Date',
      cell: (item: EngagementActionProps<EngagementInteraction>) => moment(item.actionData!.date).format('MMMM Do, YYYY')
    },
    {
      id: 'author',
      header: 'Author',
      cell: (item: EngagementActionProps<EngagementInteraction>) => this.getUserColumn(item.userId)
    }
  ]

  get sortedInteractions () {
    return this.interactions.sort((a, b) => (a.actionData!.date < b.actionData!.date ? -1 : 1));
  }

  async mounted () { }

  getUserColumn (userId: string) {
    return `
      <div class="user-details">
        <div class="avatar">
          <img src="${this.getUserImage(userId)}" />
        </div>
        <span class="name">${userId}</span>
      </div>
    `
  }
  // <span class="name">${this.getUserData(userId).given_name} ${this.getUserData(userId).family_name}</span>

  getUserData (userId: string) {
    const user = this.userList.filter(user => user.preferred_username === userId)[0];
    if (!user) {
      return {
        given_name: 'Unknown User',
        family_name: `(${userId})`
      }
    }
    return user;
  }

  getUserImage (userId: string) {
    return `${this.configService.get('EMPLOYEE_PICTURE_URL')}?uid=${userId}`
  }
}
