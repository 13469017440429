
import { Vue, Component, Prop } from 'vue-property-decorator';
import AuthService from '@/services/AuthService'
import ConfigurationService from '@/services/ConfigurationService'
import { TeamMember, TeamGroup } from '@/models/Team';

@Component
export default class UserInfo extends Vue {
  @Prop(String) readonly user!: string;
  @Prop({ default: false }) readonly mini!: boolean;
  @Prop({ default: () => ['picture', 'givenName', 'familyName', 'role'] }) readonly fields!: string[];

  private readonly auth: AuthService;
  private readonly config: ConfigurationService;

  private readonly pictureServerUrl: string;
  private realUser: TeamMember | null = null;

  constructor () {
    super();

    this.auth = AuthService.getInstance();
    this.config = ConfigurationService.getInstance();
    this.pictureServerUrl = this.config.get('EMPLOYEE_PICTURE_URL');
  }

  get avatarUrl () {
    const url = `${this.pictureServerUrl}?uid=${this.user}`;
    return url;
  }

  get userRole () {
    return this.realUser!.role;
  }

  async created () {
    // try {
    //   this.realUser = await this.protoLab.getUser(this.user);
    // } catch (e) {
    // @ts-ignore
    this.realUser = {
      Username: this.user,
      given_name: this.user,
      family_name: '',
      role: '',
      sub: ''
    }
    // }
  }
}
