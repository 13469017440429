
import { Vue, Component, Prop } from "vue-property-decorator";
import Engagement from "../../models/Engagement";
import AwsAccountIdsInput from "@/components/AWSAccountsInput.vue";
import AwsServicesInput from "@/components/AWSServicesInput.vue";
import {
  EngagementActionProps,
  EngagementAction
} from "../../models/EngagementActions";
import { Message } from "@/models/Messaging";
import Messaging from "@/components/Messaging.vue";

@Component({
  components: {
  AwsAccountIdsInput,
  AwsServicesInput,
  Messaging
  }
  })
export default class EngagementTechnicalForm extends Vue {
  @Prop(Engagement) readonly engagement!: Engagement;
  @Prop(Array) readonly actions!: EngagementActionProps[];
  @Prop(Function) readonly submit!: Function;
  @Prop(Function) readonly clear!: Function;

  async created() {}

  async mounted() {}

  get engagementMessages() {
    if (!this.actions) return [];
    return this.actions
      .filter(action => action.action === EngagementAction.PostMessage)
      .map(action => {
        const messageData = action.actionData as Message;
        return messageData;
      });
  }
}
