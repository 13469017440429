export interface AWSService {
  section: string;
  service: string;
}

export const AWSServices: AWSService[] = [
  {
    section: 'Compute',
    service: 'EC2'
  },
  {
    section: 'Compute',
    service: 'Lightsail'
  },
  {
    section: 'Compute',
    service: 'ECR'
  },
  {
    section: 'Compute',
    service: 'ECS'
  },
  {
    section: 'Compute',
    service: 'EKS'
  },
  {
    section: 'Compute',
    service: 'Lambda'
  },
  {
    section: 'Compute',
    service: 'Batch'
  },
  {
    section: 'Compute',
    service: 'Elastic Beanstalk'
  },
  {
    section: 'Compute',
    service: 'Serverless Application Repository'
  },
  {
    section: 'Storage',
    service: 'S3'
  },
  {
    section: 'Storage',
    service: 'EFS'
  },
  {
    section: 'Storage',
    service: 'FSx'
  },
  {
    section: 'Storage',
    service: 'S3 Glacier'
  },
  {
    section: 'Storage',
    service: 'Storage Gateway'
  },
  {
    section: 'Storage',
    service: 'AWS Backup'
  },
  {
    section: 'Database',
    service: 'RDS'
  },
  {
    section: 'Database',
    service: 'DynamoDB'
  },
  {
    section: 'Database',
    service: 'ElastiCache'
  },
  {
    section: 'Database',
    service: 'Neptune'
  },
  {
    section: 'Database',
    service: 'Amazon Redshift'
  },
  {
    section: 'Database',
    service: 'Amazon DocumentDB'
  },
  {
    section: 'Migration & Transfer',
    service: 'AWS Migration Hub'
  },
  {
    section: 'Migration & Transfer',
    service: 'Application Discovery Service'
  },
  {
    section: 'Migration & Transfer',
    service: 'Database Migration Service'
  },
  {
    section: 'Migration & Transfer',
    service: 'Server Migration Service'
  },
  {
    section: 'Migration & Transfer',
    service: 'AWS Transfer for SFTP'
  },
  {
    section: 'Migration & Transfer',
    service: 'Snowball'
  },
  {
    section: 'Migration & Transfer',
    service: 'DataSync'
  },
  {
    section: 'Networking & Content Delivery',
    service: 'VPC'
  },
  {
    section: 'Networking & Content Delivery',
    service: 'CloudFront'
  },
  {
    section: 'Networking & Content Delivery',
    service: 'Route 53'
  },
  {
    section: 'Networking & Content Delivery',
    service: 'API Gateway'
  },
  {
    section: 'Networking & Content Delivery',
    service: 'Direct Connect'
  },
  {
    section: 'Networking & Content Delivery',
    service: 'AWS Cloud Map'
  },
  {
    section: 'Networking & Content Delivery',
    service: 'Global Accelerator'
  },
  {
    section: 'Developer Tools',
    service: 'CodeStar'
  },
  {
    section: 'Developer Tools',
    service: 'CodeCommit'
  },
  {
    section: 'Developer Tools',
    service: 'CodeBuild'
  },
  {
    section: 'Developer Tools',
    service: 'CodeDeploy'
  },
  {
    section: 'Developer Tools',
    service: 'CodePipeline'
  },
  {
    section: 'Developer Tools',
    service: 'Cloud9'
  },
  {
    section: 'Developer Tools',
    service: 'X-Ray'
  },
  {
    section: 'Robotics',
    service: 'AWS RoboMaker'
  },
  {
    section: 'Database',
    service: 'Amazon QLDB'
  },
  {
    section: 'Blockchain',
    service: 'Amazon Managed Blockchain'
  },
  {
    section: 'Satellite',
    service: 'Ground Station'
  },
  {
    section: 'Management & Governance',
    service: 'CloudWatch'
  },
  {
    section: 'Management & Governance',
    service: 'EventBridge'
  },
  {
    section: 'Management & Governance',
    service: 'AWS Auto Scaling'
  },
  {
    section: 'Management & Governance',
    service: 'CloudFormation'
  },
  {
    section: 'Management & Governance',
    service: 'CloudTrail'
  },
  {
    section: 'Management & Governance',
    service: 'Config'
  },
  {
    section: 'Management & Governance',
    service: 'OpsWorks'
  },
  {
    section: 'Management & Governance',
    service: 'Service Catalog'
  },
  {
    section: 'Management & Governance',
    service: 'Systems Manager'
  },
  {
    section: 'Management & Governance',
    service: 'Trusted Advisor'
  },
  {
    section: 'Management & Governance',
    service: 'Managed Services'
  },
  {
    section: 'Management & Governance',
    service: 'Control Tower'
  },
  {
    section: 'Management & Governance',
    service: 'AWS License Manager'
  },
  {
    section: 'Management & Governance',
    service: 'AWS Well-Architected Tool'
  },
  {
    section: 'Management & Governance',
    service: 'Personal Health Dashboard'
  },
  {
    section: 'Media Services',
    service: 'Elastic Transcoder'
  },
  {
    section: 'Media Services',
    service: 'Kinesis Video Streams'
  },
  {
    section: 'Media Services',
    service: 'MediaConnect'
  },
  {
    section: 'Media Services',
    service: 'MediaConvert'
  },
  {
    section: 'Media Services',
    service: 'MediaLive'
  },
  {
    section: 'Media Services',
    service: 'MediaPackage'
  },
  {
    section: 'Media Services',
    service: 'MediaStore'
  },
  {
    section: 'Media Services',
    service: 'MediaTailor'
  },
  {
    section: 'Machine Learning',
    service: 'Amazon SageMaker'
  },
  {
    section: 'Machine Learning',
    service: 'Amazon Comprehend'
  },
  {
    section: 'Machine Learning',
    service: 'AWS DeepLens'
  },
  {
    section: 'Machine Learning',
    service: 'Amazon Lex'
  },
  {
    section: 'Machine Learning',
    service: 'Machine Learning'
  },
  {
    section: 'Machine Learning',
    service: 'Amazon Polly'
  },
  {
    section: 'Machine Learning',
    service: 'Rekognition'
  },
  {
    section: 'Machine Learning',
    service: 'Amazon Transcribe'
  },
  {
    section: 'Machine Learning',
    service: 'Amazon Translate'
  },
  {
    section: 'Machine Learning',
    service: 'Amazon Personalize'
  },
  {
    section: 'Machine Learning',
    service: 'Amazon Forecast'
  },
  {
    section: 'Machine Learning',
    service: 'Amazon Textract'
  },
  {
    section: 'Analytics',
    service: 'Athena'
  },
  {
    section: 'Analytics',
    service: 'EMR'
  },
  {
    section: 'Analytics',
    service: 'CloudSearch'
  },
  {
    section: 'Analytics',
    service: 'Elasticsearch Service'
  },
  {
    section: 'Analytics',
    service: 'Kinesis'
  },
  {
    section: 'Analytics',
    service: 'QuickSight'
  },
  {
    section: 'Analytics',
    service: 'Data Pipeline'
  },
  {
    section: 'Analytics',
    service: 'AWS Glue'
  },
  {
    section: 'Analytics',
    service: 'MSK'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'IAM'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'Resource Access Manager'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'Cognito'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'Secrets Manager'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'GuardDuty'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'Inspector'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'Amazon Macie'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'AWS Organizations'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'AWS Single Sign-On'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'Certificate Manager'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'Key Management Service'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'CloudHSM'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'Directory Service'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'WAF & Shield'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'Artifact'
  },
  {
    section: 'Security, Identity, & Compliance',
    service: 'Security Hub'
  },
  {
    section: 'AWS Cost Management',
    service: 'AWS Cost Explorer'
  },
  {
    section: 'AWS Cost Management',
    service: 'AWS Budgets'
  },
  {
    section: 'AWS Cost Management',
    service: 'AWS Marketplace Subscriptions'
  },
  {
    section: 'Mobile',
    service: 'AWS Amplify'
  },
  {
    section: 'Mobile',
    service: 'Mobile Hub'
  },
  {
    section: 'Mobile',
    service: 'AWS AppSync'
  },
  {
    section: 'Mobile',
    service: 'Device Farm'
  },
  {
    section: 'AR & VR',
    service: 'Amazon Sumerian'
  },
  {
    section: 'Application Integration',
    service: 'Step Functions'
  },
  {
    section: 'Application Integration',
    service: 'Amazon MQ'
  },
  {
    section: 'Application Integration',
    service: 'Simple Team Service'
  },
  {
    section: 'Application Integration',
    service: 'Simple Queue Service'
  },
  {
    section: 'Application Integration',
    service: 'SWF'
  },
  {
    section: 'Customer Engagement',
    service: 'Amazon Connect'
  },
  {
    section: 'Customer Engagement',
    service: 'Pinpoint'
  },
  {
    section: 'Customer Engagement',
    service: 'Simple Email Service'
  },
  {
    section: 'Business Applications',
    service: 'Alexa for Business'
  },
  {
    section: 'Business Applications',
    service: 'Amazon Chime'
  },
  {
    section: 'Business Applications',
    service: 'WorkMail'
  },
  {
    section: 'End User Computing',
    service: 'WorkSpaces'
  },
  {
    section: 'End User Computing',
    service: 'AppStream 2.0'
  },
  {
    section: 'End User Computing',
    service: 'WorkDocs'
  },
  {
    section: 'End User Computing',
    service: 'WorkLink'
  },
  {
    section: 'Internet of Things',
    service: 'IoT Core'
  },
  {
    section: 'Internet of Things',
    service: 'Amazon FreeRTOS'
  },
  {
    section: 'Internet of Things',
    service: 'IoT 1-Click'
  },
  {
    section: 'Internet of Things',
    service: 'IoT Analytics'
  },
  {
    section: 'Internet of Things',
    service: 'IoT Device Defender'
  },
  {
    section: 'Internet of Things',
    service: 'IoT Device Management'
  },
  {
    section: 'Internet of Things',
    service: 'IoT Events'
  },
  {
    section: 'Internet of Things',
    service: 'IoT Greengrass'
  },
  {
    section: 'Internet of Things',
    service: 'IoT SiteWise'
  },
  {
    section: 'Internet of Things',
    service: 'IoT Things Graph'
  },
  {
    section: 'Game Development',
    service: 'Amazon GameLift'
  }
];
