
import { Vue, Component } from "vue-property-decorator";
import EngagementsService from "@/services/EngagementsService";
import S3Service from "@/services/S3Service";

// @ts-ignore
import { ReactInVue } from "vuera";
import ReactFileSearch from "../components/fileSearch";

@Component({
  components: {
  'file-search': ReactInVue(ReactFileSearch),
  },
  filters: {
  competencyTitle (value: any): string {
  return "N/A";
  }
  }
  })
export default class FileSearch extends Vue {
  private readonly engagementsService: EngagementsService;
  private readonly s3Service: S3Service;

  constructor() {
    super();

    this.s3Service = S3Service.getInstance();
    this.engagementsService = EngagementsService.getInstance();
  }
}
