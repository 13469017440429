
import moment from "moment";
import AuthService from "@/services/AuthService";
import EngagementsService from "@/services/EngagementsService";
import { Vue, Component } from "vue-property-decorator";
import Banner from "@/components/Banner.vue";
import MenuItem from "@/components/MenuItem.vue";
import Engagement from "../models/Engagement";
import AssignmentFollowUps from '../components/architects/landing/AssignmentFollowUps.vue';
import EngagementContextOverview from '../components/management/EngagementContextOverview.vue';

export interface LandingMenuItem {
  icon: string;
  label: string;
  link: string;
}

@Component({
  components: {
  AssignmentFollowUps,
  Banner,
  EngagementContextOverview,
  MenuItem,
  },
  filters: {
  weekModifier(modifier: any) {
  const date = new Date();
  const weekNo = parseInt(moment(date).format("ww"));

  const startDate = new Date(
    date.getFullYear(),
    0,
    1 + (weekNo + modifier - 1) * 7
  );
  const weekDate = startDate.getDay();
  startDate.setDate(startDate.getDate() - weekDate + 1);

  return moment(startDate).format("MMM DD ([w]ww)");
  }
  }
  })
export default class LandingComponent extends Vue {
  private readonly auth: AuthService = AuthService.getInstance();
  private readonly engagementsService: EngagementsService = EngagementsService.getInstance();

  public engagementList: Engagement[] = [];
  public engagementFiles: any[] = [];

  public team: string = this.auth.team || '';

  get archDiagram() {
    const images = this.engagementFiles.filter(item => {
      const extension = item.Key.split(".").pop();
      return ["png", "jpg", "jpeg"].indexOf(extension) !== -1;
    });

    if (!images.length) return null;

    const image = images[0];
    const extension = image.Key.split(".").pop();
    return `data:image/${extension};base64,${Buffer.from(image.Body).toString(
      "base64"
    )}`;
  }

  get currentEngagements() {
    return this.engagementList;
  }

  get currentRole() {
    return 'Architect';
  }

  get currentUser() {
    return this.auth.user;
  }

  get monthDays() {
    const date = new Date();
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const days = [];
    let current = start;
    while (current.getTime() < end.getTime()) {
      const weekDay = current.getDay();
      if ([0, 6].indexOf(weekDay) === -1) {
        days.push(current);
      }

      current = new Date(
        current.getFullYear(),
        current.getMonth(),
        current.getDate() + 1
      );
    }

    return days.length;
  }

  async created() {
    this.fetchData();
  }

  async fetchData() {
    const currentDate = new Date();
    const currentWeek = parseInt(moment(currentDate).format("YYYYww"));

    // Get engagements
    this.engagementList = await this.engagementsService.listEngagements();
  }

  getEngagementById(engagementId: string): Engagement {
    return this.engagementList.filter(
      eng => eng.id === engagementId
    )[0];
  }
}
