export enum EngagementAction {
  AddFile           = 'add-file',
  Archive           = 'archive',
  ArchiveEngagement = 'archive-engagement',
  AssignTechLead    = 'assign-tech-lead',
  ClosePlanning     = 'close-planning',
  FinishExecution   = 'finish-execution',
  LogInteraction    = 'log-interaction',
  OnboardingRequest = 'onboarding-request',
  PostMessage       = 'post-message',
  ReopenPlanning    = 'reopen-planning',
  ResumeExecution   = 'resume-execution',
  SetWinStatus      = 'set-win-status',
  SubmitClosure     = 'submit-closure',
  UpdateAWSServices = 'update-aws-services',
  UpdateData        = 'update-data',
  UpdateDuration    = 'update-duration',
  UpdateStartDate   = 'update-start-date',
  UpdateStatus      = 'update-status',

  // TODO Others
}

export interface EngagementActionProps<T = { [key: string]: any }> {
  id: string,
  engagementId: string,
  userId: string,
  teamId: string,
  action: EngagementAction,
  actionDate: Date | number | string,
  actionData?: T
}
